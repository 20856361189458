import React from 'react';
import AdminTable from 'layouts/AdminTable';
import HTML5Backend from 'react-dnd-html5-backend';
import DragableTable from '../DragableTable';
import './styles.css';
import { DndProvider } from 'react-dnd';
import { sortByKey } from 'helpers/helpers';

import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import Table from '@material-ui/core/Table';
import Check from '@material-ui/icons/Check';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';

import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import SuperVariantBetaPricesTable from '../components/SuperVariantBetaPricesTable';

const FormVariantsView = ({ props }) => {
  const {
    state,
    setState,
    isLoading,
    isEdit,
    setCalories,
    handleNameChange,
    handleVariantName,
    handleVariantWorkName,
    toggleCalorificActivness,
    removeRow,
    handleShortNameChange,
    handlePositionChange,
    handleChangeIntField,
    handleDiets,
    toggleCheckboxState,
    handleToggle,
    handleMealTypes,
    handleDescriptionChange,
    handleInternalPositionChange,
    addCalorie,
    getSizeName,
    getImage,
    removeImage,
    getRowSum,
    openModal,
    handleSubmit,
    classes,
    mealTypes,
    variant,
    t,
    diets,
    multinational,
  } = props;

  return (
    isLoading && (
      <AdminTable
        title={isEdit ? t('form.editVariant') : t('form.addNewVariant')}
      >
        {state.modal}
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={t('form.field.variantName') + '*'}
              classes={classes}
              name="variant.name"
              value={state.variant.name}
              handleChange={handleNameChange}
              inputSize={12}
              maxLength={64}
            />
          </GridItem>
          <GridItem sm={1}>
            <FormTextInput
              label={t('columns.code') + '*'}
              classes={classes}
              name="variant.shortName"
              value={state.variant.shortName}
              handleChange={handleShortNameChange}
              inputSize={12}
              maxLength={32}
            />
          </GridItem>
          <GridItem sm={1}>
            <FormTextInput
              label={t('form.field.order') + '*'}
              classes={classes}
              name="variant.position"
              type="number"
              value={state.variant.position}
              handleChange={handlePositionChange}
              inputSize={12}
              maxLength={6}
              inputProps={{ min: 0 }}
            />
          </GridItem>
          <GridItem sm={3}>
            <FormTextInput
              label={t('form.field.internalOrder') + '*'}
              classes={classes}
              name="internalPosition"
              type="number"
              value={state.variant.internalPosition}
              handleChange={handleInternalPositionChange}
              inputSize={12}
              maxLength={6}
              inputProps={{ min: 0 }}
            />
          </GridItem>
          <GridItem sm={3} />
          <GridItem sm={5}>
            <FormTextInput
              multiline
              label={t('form.field.description')}
              classes={classes}
              name="description"
              value={state.variant.description}
              handleChange={handleDescriptionChange}
              inputSize={12}
              maxLength={500}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => toggleCheckboxState('active')}
                  checked={state.variant.active}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={
                <div style={{ display: 'flex' }}>
                  {t('form.field.active.label')}{' '}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('form.variantActiveInfo1')}:</h4>
                          <ul style={{ fontSize: '14px' }}>
                            <li>{t('form.variantActiveInfo2')}</li>
                            <li>{t('form.variantActiveInfo3')}</li>
                          </ul>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() =>
                    toggleCheckboxState('allowSelectMenuFromOtherVariants')
                  }
                  checked={state.variant.allowSelectMenuFromOtherVariants}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={
                <div style={{ display: 'flex' }}>
                  {t('form.field.allowSelectMenuFromOtherVariants.label')}{' '}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>
                            {t(
                              'form.variantAllowSelectMenuFromOtherVariantsInfo1'
                            )}
                            :
                          </h4>
                          <ul style={{ fontSize: '14px' }}>
                            <li>
                              {t(
                                'form.variantAllowSelectMenuFromOtherVariantsInfo2'
                              )}
                            </li>
                          </ul>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
        </GridContainer>
        <SelectInput
          multiple
          classes={classes}
          label={t('form.field.avDiets')}
          mapBy="name"
          trackBy="@id"
          value={state.variant.diets}
          options={diets}
          handleChange={(ev, obj) => handleDiets(ev, obj)}
        />
        <SelectInput
          classes={classes}
          multiple={true}
          label={t('form.field.mealTypes') + '*'}
          mapBy="name"
          trackBy="@id"
          value={state.variant.mealTypes}
          options={sortByKey(mealTypes, 'position')}
          handleChange={handleMealTypes}
          id="types"
          size={12}
        />
        <GridContainer style={{ marginTop: '20px' }}>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {t('form.field.variantPhoto')}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
            <FormImageUpload
              classes={classes}
              stateName="image"
              getImage={getImage}
              removeImage={removeImage}
              previewUrl={state.imageUrl}
            />
          </GridItem>
        </GridContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          {state.variant?.mealTypes?.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell align={'left'}>{t('form.field.name')} *</TableCell>
                <TableCell align={'left'}>
                  {t('form.field.workingName')}
                </TableCell>
                {state.variant.mealTypes.map((mealType, columnIndex) => {
                  return (
                    <TableCell
                      key={columnIndex}
                      align={'center'}
                      padding={'none'}
                    >
                      {mealType.name} *
                    </TableCell>
                  );
                })}
                <TableCell align={'left'}>{t('diets.active')}</TableCell>
                <TableCell align={'left'}>
                  {t('form.field.caloriesSum')}
                </TableCell>
              </TableRow>
            </TableHead>
          )}
        </Table>
        <DndProvider backend={HTML5Backend}>
          <DragableTable
            calories={state.variant.calories}
            variant={state.variant}
            setCalories={setCalories}
            getSizeName={getSizeName}
            getRowSum={getRowSum}
            openModal={openModal}
            handleVariantName={handleVariantName}
            handleVariantWorkName={handleVariantWorkName}
            removeRow={removeRow}
            toggleCalorificActivness={toggleCalorificActivness}
          />
        </DndProvider>
        <Table>
          <TableBody>
            {state.variant.mealTypes.length > 0 && (
              <TableRow>
                <TableCell
                  className={'variant-addRow'}
                  colSpan={variant?.mealTypes?.length + 2}
                  onClick={addCalorie}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ display: 'block' }}>
                      + {t('form.addCalories')}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div style={{ marginTop: '25px' }}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} lg={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle('superVariantBeta')}
                        checked={state.variant.superVariantBeta}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={
                      <div style={{ display: 'flex' }}>
                        {t(
                          'form.field.superVariantBeta.label',
                          'Superwariant Beta'
                        )}{' '}
                      </div>
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={6} lg={9}>
                  {state.variant.superVariantBeta && (
                    <GridContainer style={{ justifyContent: 'end' }}>
                      <GridItem>
                        <FormTextInput
                          label={
                            t(
                              'form.field.minMealTypes',
                              'Minimalna ilość wybranych posiłków'
                            ) + '*'
                          }
                          classes={classes}
                          name="minMealTypes"
                          type="number"
                          value={state.variant.minMealTypes}
                          handleChange={handleChangeIntField}
                          inputSize={12}
                          maxLength={6}
                          inputProps={{ min: 0 }}
                        />
                      </GridItem>
                      <GridItem>
                        <FormTextInput
                          label={
                            t(
                              'form.field.minPrice',
                              'Minimalna wartość wariantu'
                            ) + '*'
                          }
                          classes={classes}
                          name="minPrice"
                          type="number"
                          value={state.variant.minPrice}
                          handleChange={handleChangeIntField}
                          inputSize={12}
                          maxLength={6}
                          inputProps={{ min: 0 }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
              {state.variant.superVariantBeta && (
                <>
                  <GridContainer>
                    <GridItem xs={12} style={{ textAlign: 'center' }}>
                      <h3>
                        {t(
                          'form.field.variant.valueConfiguration',
                          'Konfiguracja wartości'
                        )}
                      </h3>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      <SuperVariantBetaPricesTable
                        multinational={multinational}
                        t={t}
                        classes={classes}
                        key={`svbpt-${(state.variant.calories ?? []).length}`}
                        mealTypes={state.variant.mealTypes ?? []}
                        calories={state.variant.calories ?? []}
                        onChange={val =>
                          setState(prevState => ({
                            ...prevState,
                            variant: { ...prevState.variant, calories: val },
                          }))
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </>
              )}
            </CardBody>
          </Card>
        </div>
        <div style={{ marginTop: '25px' }}>
          <FormControlButtons
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.save')}
            cancelPath="/admin/variants"
            handleSubmit={handleSubmit}
          />
        </div>
      </AdminTable>
    )
  );
};

export default FormVariantsView;
