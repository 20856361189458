import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { whiteColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles({
  tooltip: {
    maxWidth: '300px',
    color: whiteColor,
  },
  info: {
    marginLeft: '5px',
    color: 'grey',
    fontSize: '16px',
  },
  childrenWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

const InfoIconTooltip = ({ children, title }) => {
  const classes = useStyles();

  const infoIcon = <Info className={classes.info} />;

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<h4>{title}</h4>}
      placement="right"
    >
      {children ? (
        <span className={classes.childrenWrapper}>
          {children}
          {infoIcon}
        </span>
      ) : (
        infoIcon
      )}
    </Tooltip>
  );
};

export default InfoIconTooltip;
