import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';

import { infoColor, whiteColor } from 'assets/jss/material-dashboard-pro-react';
import { get } from 'helpers/apiHelpers';
const useStyles = makeStyles({
  button: {
    margin: '5px auto',
    display: 'block',
    backgroundColor: infoColor[0],
    color: whiteColor,
    padding: '2px 30px',
  },
  infoIcon: {
    width: '18px',
    color: '#808080',
    marginLeft: '10px',
  },
});

const KwgGroupsCard = ({ mealTypeSizes, dishSizes, dish, activeBrandId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        {t('dish.list.KwgGroupsCard', 'Karta wyrobu gotowego')}
        <Tooltip
          title={
            <div>
              <h4>
                {t('dish.list.KwgGroupsCard.tooltip.title')}
                <ul>
                  <Trans
                    i18nKey="dish.list.KwgGroupsCard.tooltip"
                    components={{
                      li: <li />,
                    }}
                  />
                </ul>
              </h4>
            </div>
          }
          placement="top"
        >
          <Info
            className={classes.infoIcon}
            fontSize={'small'}
            color={'disabled'}
          />
        </Tooltip>
      </TableCell>
      <TableCell />
      <TableCell />
      {mealTypeSizes.map(mealTypeSize => {
        return (
          <TableCell key={mealTypeSize.id}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                const [dishSize] = dishSizes.filter(({ size }) => {
                  return size === mealTypeSize.size['@id'];
                });
                get(`/dish-sizes/${dishSize.id}/kwg`, null, {
                  responseType: 'blob',
                  headers: { 'X-G-BRAND': activeBrandId },
                }).then(response => {
                  const url = window.URL.createObjectURL(response);
                  const link = document.createElement('a');
                  link.href = url;
                  link.style.display = 'none';
                  link.setAttribute(
                    'download',
                    `Karta wyrobu gotowego ${dish.workingName}_${dishSize.code}.pdf`
                  );
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              }}
            >
              {t('dish.list.KwgGroups', 'Pobierz')}
            </Button>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default KwgGroupsCard;
