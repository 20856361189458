import { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import {
  ROLE_CREATE_RECIPE,
  ROLE_EDIT_RECIPE,
  ROLE_SHOW_RECIPE,
} from 'helpers/roles';
import withRole from 'components/Security/withRole';
import List from 'views/Recipes/List/Recipes';
import RecipeForm from 'views/Recipes/Form/RecipeForm';

import RecipesContainer from './Form/RecipesContainer';

const RecipeFormWithContainer = RecipesContainer(RecipeForm);

class Recipes extends Component {
  render() {
    const { history, match } = this.props;

    const ListRoute = withRole(ROLE_SHOW_RECIPE, List);
    const CreateRoute = withRole(ROLE_CREATE_RECIPE, RecipeFormWithContainer);
    const EditRoute = withRole(ROLE_EDIT_RECIPE, RecipeFormWithContainer);

    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={routerProps => <ListRoute {...routerProps} />}
          />
          <Route
            path={`${match.url}/add`}
            render={routerProps => <CreateRoute {...routerProps} />}
          />
          <Route
            path={`${match.url}/edit/:id`}
            render={routerProps => <EditRoute {...routerProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Recipes);
