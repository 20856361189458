import { useTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import { isGranted } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import {
  ROLE_CREATE_KWG_GROUP,
  ROLE_SHOW_KWG_GROUP,
  ROLE_EDIT_KWG_GROUP,
  ROLE_REMOVE_KWG_GROUP,
} from 'helpers/roles';

const GroupOfFinishedProductsList = ({ history, classes }) => {
  const { t } = useTranslation();

  const handleNewSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable>
      {isGranted(ROLE_CREATE_KWG_GROUP) && (
        <>
          <FormControlButtons
            classes={classes}
            handleSubmit={handleNewSize}
            submitText={t('form.addKwgGroup') + ' +'}
          />
        </>
      )}
      {console.log('nie powinno 2')}
      <DataGrid
        actions={
          isGranted(ROLE_EDIT_KWG_GROUP) || isGranted(ROLE_REMOVE_KWG_GROUP)
        }
        editPath={
          isGranted(ROLE_EDIT_KWG_GROUP) && `${window.location.pathname}/edit`
        }
        remove={isGranted(ROLE_REMOVE_KWG_GROUP)}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/kwg-groups"
        columns={[
          {
            title: 'ID',
            accessor: 'id',
            name: 'id',
            filterable: true,
            width: 300,
          },
          {
            title: 'form.field.nameGropupOfFinishedProduct',
            name: 'name',
            accessor: 'name',
            filterable: true,
          },
        ]}
        role="kwg_group"
        manipulateQuery={(table, query) => {
          return query;
        }}
      />
    </AdminTable>
  );
};

export default withStyles(buttonsStyle)(GroupOfFinishedProductsList);
