import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_RECIPE } from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ListHeader from './ListHeader';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={<ListHeader />}>
        {isGranted(ROLE_CREATE_RECIPE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('form.addRecipe') + ' +'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/recipes"
          reportName={'recipe'}
          columns={columnConfig(this.props.t)}
          role="RECIPE"
          defaultHiddenColumns={['createdAt']}
          manipulateQuery={(table, query) => {
            query['properties'] = [
              'id',
              'nameForClient',
              'workName',
              'price',
              'allergens',
              'value',
              'createdAt',
              'type',
            ];
            query['properties[tags]'] = ['value'];
            query['properties[ingredients][ingredient]'] = ['workName'];
            query['properties[ingredients][ingredient][allergens]'] = ['value'];

            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(buttonsStyle),
  connect(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => ({
      multinational,
    })
  )
);

export default enhance(List);
