import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { concat, uniq, uniqBy } from 'lodash';
import update from 'immutability-helper';
import { compose } from 'recompose';
import { withToast } from 'material-ui-toast-redux';

import { fetchAllergens } from 'actions/Allergens';
import { fetchDepartments } from 'actions/Departments';
import { fetchKitchens } from 'actions/Kitchens';
import { fetchIngredient, fetchIngredients } from 'actions/Ingredients';
import gastro from 'helpers/gastro';
import { get, post, put } from 'helpers/apiHelpers';
import { countDecimal, toDecimalPlaces } from 'helpers/helpers';
import TOAST_DURATIONS from 'helpers/toastDurations';
import {
  TranslatableObjectKey,
  useGetCompanyDefaultLanguage,
} from 'hooks/redux/Translatable/useTranslatableLanguages';

import useGetFormDefaultState from './hooks/useGetFormDefaultState';
import useGetTranslatableObject from './hooks/useGetTranslatableObject';

const LOCAL_STORAGE_SAVED_RECIPE = 'savedRecipe';

const withHooks = RecipesContainerComponent => {
  return function WithHooks(props) {
    const companyDefaultLanguage = useGetCompanyDefaultLanguage();
    const formDefaultState = useGetFormDefaultState();
    const getTranslatableObject = useGetTranslatableObject();

    return (
      <RecipesContainerComponent
        {...props}
        companyDefaultLanguage={companyDefaultLanguage}
        formDefaultState={formDefaultState}
        getTranslatableObject={getTranslatableObject}
      />
    );
  };
};

const RecipesContainer = WrappedComponent =>
  class extends Component {
    state = {
      ...this.props.formDefaultState,
      isLoading: true,
      isCommonWot: false,
      commonWotValue: null,
    };

    recipeId = this.props.match.params.id;
    isEdit = this.props.location.pathname.includes('edit');

    fetchTags = async () => {
      const response = await get('/tags', { pagination: false });

      this.setState({
        tags: uniqBy(response['hydra:member'], 'value'),
      });

      if (this.props.location.search.includes('savedRecipe')) {
        const savedRecipe = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_SAVED_RECIPE)
        );

        this.setState(savedRecipe);
        localStorage.removeItem(LOCAL_STORAGE_SAVED_RECIPE);
      }
    };

    getRecipe = async () => {
      const { data: recipe } = await gastro.get(`/recipes/${this.recipeId}`, {
        params: {
          translations: true,
        },
      });

      const ingredientsPromises = recipe.ingredients.map(
        async recipeIngredient => {
          const [, , ingredientId] = recipeIngredient.ingredient.split('/');
          const ingredient = await this.props.fetchIngredient(ingredientId);

          return {
            ...ingredient,
            recipeIngredientId: recipeIngredient['@id'],
            quantity: recipeIngredient.quantity,
            thermalProcessing: parseFloat(
              recipeIngredient.thermalProcessing.toFixed(2)
            ),
            workingOnMachining: parseFloat(
              recipeIngredient.workingOnMachining.toFixed(2)
            ),
          };
        }
      );

      const ingredients = await Promise.all(ingredientsPromises);

      this.setState({
        type: recipe.type,
        workNameState: recipe.workName,
        clientNameState: recipe.clientName,
        purposeState: recipe.purpose,
        difficultyState: recipe.difficulty,
        selectedTags: recipe.tags,
        recipeState: recipe.recipe,
        preparationTime: recipe.preparationTime,
        userIngredients: ingredients.map(ingredient => {
          const ingredientQuantity =
            countDecimal(ingredient.quantity) <=
            this.props.dishIngredientsDecimalPlaces
              ? ingredient.quantity
              : toDecimalPlaces(
                  ingredient.quantity,
                  this.props.dishIngredientsDecimalPlaces
                );

          return {
            ...ingredient,
            weight: (ingredient.weight * ingredientQuantity).toFixed(2),
            weightDefault: ingredient.weight,
            userValue: ingredientQuantity,
            wpOM: (
              (ingredient.workingOnMachining / 100) *
              (ingredient.weight * ingredientQuantity)
            ).toFixed(2),
          };
        }),
        kitchenImageState: recipe.kitchenImage?.['@id'] || null,
        kitchenImageStateUrl: recipe.kitchenImage?.contentUrl || null,
        clientImageState: recipe.clientImage?.['@id'] || null,
        clientImageStateUrl: recipe.clientImage?.contentUrl || null,
        defaultWorker: recipe?.defaultWorker ?? null,
        departmentState: recipe?.department ?? null,
        kitchenState: recipe?.kitchen ?? null,
        nameForClient: this.props.getTranslatableObject(recipe.nameForClient),
      });
    };

    handleChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    handleTagChange = selectedTags => {
      this.setState({
        selectedTags,
      });
    };

    selectEmployee = defaultWorker => {
      this.setState({
        defaultWorker,
      });
    };

    addIngredient = ingredientToAdd => {
      if (
        !this.state.userIngredients.some(
          ingredient => ingredient['@id'] === ingredientToAdd['@id']
        )
      ) {
        this.setState({
          userIngredients: [
            ...this.state.userIngredients,
            {
              ...ingredientToAdd,
              userValue: 1,
              weightDefault: ingredientToAdd.weight,
              wpOM: (
                (ingredientToAdd.workingOnMachining / 100) *
                ingredientToAdd.weight
              ).toFixed(2),
            },
          ],
        });
      } else {
        alert(this.props.t('errors.thisIngredientHasAlreadyBeenAdded'));
        return null;
      }
    };

    removeIngredient = ingredient => {
      this.setState({
        userIngredients: this.state.userIngredients.filter(
          userIngredient => userIngredient.id !== ingredient.id
        ),
      });
    };

    getNewWpOM = (index, newWeight, newWOM) => {
      const workingOnMachining =
        newWOM ?? this.state.userIngredients[index].workingOnMachining;

      return ((workingOnMachining / 100) * newWeight).toFixed(2);
    };

    handleUserValue = (index, event) => {
      const { value } = event.target;
      const newIserValue =
        countDecimal(value) <= this.props.dishIngredientsDecimalPlaces
          ? value
          : toDecimalPlaces(value, this.props.dishIngredientsDecimalPlaces);

      const weightDefault = this.state.userIngredients[index].weightDefault;

      const newWeight = (newIserValue * weightDefault).toFixed(2);
      const newWpOM = this.getNewWpOM(index, newWeight);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: { $set: newIserValue },
            weight: { $set: newWeight },
            wpOM: { $set: newWpOM },
          },
        }),
      });
    };

    handleQuantity = (index, event) => {
      const { value } = event.target;

      const weightDefault = this.state.userIngredients[index].weightDefault;

      const newWeight =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      const userValue = newWeight / weightDefault;
      const newUserValue =
        countDecimal(userValue) <= this.props.dishIngredientsDecimalPlaces
          ? userValue
          : toDecimalPlaces(userValue, this.props.dishIngredientsDecimalPlaces);

      const newWpOM = this.getNewWpOM(index, newWeight);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: {
              $set: newUserValue,
            },
            weight: {
              $set: newWeight,
            },
            wpOM: {
              $set: newWpOM,
            },
          },
        }),
      });
    };

    handleThermalProcessing = (index, event) => {
      const { value } = event.target;
      const newValue =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            thermalProcessing: { $set: parseFloat(newValue) },
          },
        }),
      });
    };

    handleWorkingOnMachining = (index, event) => {
      const { value } = event.target;

      const weight = this.state.userIngredients[index].weight;

      const newWorkingOnMachining =
        countDecimal(value) <= 2 ? value : toDecimalPlaces(value);
      const newWpOM = this.getNewWpOM(index, weight, newWorkingOnMachining);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            workingOnMachining: {
              $set: parseFloat(newWorkingOnMachining),
            },
            wpOM: { $set: newWpOM },
          },
        }),
      });
    };

    handleWpOM = (index, event) => {
      const { value } = event.target;
      const newWpOM = countDecimal(value) <= 2 ? value : toDecimalPlaces(value);

      const newWeight =
        newWpOM / (this.state.userIngredients[index].workingOnMachining / 100);

      const weightDefault = this.state.userIngredients[index].weightDefault;
      const newWeightValue =
        countDecimal(newWeight) <= 2 ? newWeight : toDecimalPlaces(newWeight);

      const userValue = newWeightValue / weightDefault;
      const newUserValue =
        countDecimal(userValue) <= this.props.dishIngredientsDecimalPlaces
          ? userValue
          : toDecimalPlaces(userValue, this.props.dishIngredientsDecimalPlaces);

      this.setState({
        userIngredients: update(this.state.userIngredients, {
          [index]: {
            userValue: {
              $set: newUserValue,
            },
            weight: {
              $set: newWeightValue,
            },
            wpOM: {
              $set: newWpOM,
            },
          },
        }),
      });
    };

    getAllergens = () =>
      uniq(
        concat(
          ...this.state.userIngredients.map(({ allergens }) =>
            allergens.map(({ value }) => value)
          )
        )
      );

    getImage = (stateName, data) => {
      this.setState({
        [stateName]: data?.['@id'] || null,
        [`${stateName}Url`]: data?.contentUrl || null,
      });
    };

    removeImage = stateName => {
      this.setState({
        [stateName]: null,
        [`${stateName}Url`]: null,
      });
    };

    filterIngredients = value => {
      return this.props.fetchIngredients({
        pageSize: 30,
        page: 0,
        sorted: [],
        filtered: [
          {
            id: '_orX',
            value: [[{ id: value, name: value, workName: value }]],
          },
        ],
      });
    };

    getNameForClientInCompanyDefaultLanguage = () => {
      return this.state.nameForClient[TranslatableObjectKey][
        this.props.companyDefaultLanguage
      ];
    };

    hasUserIngredients = () => {
      return this.state.userIngredients.length > 0;
    };

    isFormValid = () => {
      return !!(
        this.getNameForClientInCompanyDefaultLanguage() &&
        this.state.difficultyState
      );
    };

    saveToLs = () => {
      const clonedState = JSON.parse(JSON.stringify(this.state));
      const recipeToSave = {
        ...clonedState,
        userIngredients: clonedState.userIngredients.map(userIngredient => {
          return {
            ...userIngredient,
            recipeIngredientId: null,
          };
        }),
      };

      localStorage.setItem(
        LOCAL_STORAGE_SAVED_RECIPE,
        JSON.stringify(recipeToSave)
      );
    };

    handleSubmit = async () => {
      if (!this.hasUserIngredients()) {
        return this.props.openToast({
          messages: [this.props.t('errors.recipeRequiresIngredients')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      if (!this.isFormValid()) {
        return this.props.openToast({
          messages: [this.props.t('errors.fillAllRequiredFields')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      }

      const data = {
        type: this.state.type,
        nameForClient: this.state.nameForClient,
        workName:
          this.state.workNameState ||
          this.getNameForClientInCompanyDefaultLanguage(),
        clientName: this.state.clientNameState,
        difficulty: this.state.difficultyState,
        tags: this.state.selectedTags,
        recipe: this.state.recipeState,
        preparationTime: parseFloat(this.state.preparationTime),
        ingredients: this.state.userIngredients.map(ingredient => {
          return {
            '@id': ingredient.recipeIngredientId,
            ingredient: ingredient['@id'],
            quantity: parseFloat(ingredient.userValue),
            thermalProcessing:
              ingredient.thermalProcessing === null
                ? 0
                : ingredient.thermalProcessing,
            workingOnMachining:
              ingredient.workingOnMachining === null
                ? 0
                : ingredient.workingOnMachining,
          };
        }),
        kitchenImage: this.state.kitchenImageState,
        defaultWorker:
          this.state.defaultWorker?.['@id'] ?? this.state.defaultWorker,
        clientImage: this.state.clientImageState,
        department: this.state.departmentState,
        kitchen: this.state.kitchenState,
      };

      this.isEdit
        ? await put(`/recipes/${this.recipeId}`, data)
        : await post('/recipes', data);

      this.props.history.push('/admin/recipes');
    };

    handleClickCommonWot = () => {
      this.setState({ isCommonWot: !this.state.isCommonWot });
    };

    handleChangeCommonWotValue = event => {
      const { value } = event.target;

      if (!value) return;

      this.setState(prevState => ({
        ...prevState,
        commonWotValue: value,
        userIngredients: prevState.userIngredients.map(userIngredient => ({
          ...userIngredient,
          thermalProcessing: parseFloat(value),
        })),
      }));
    };

    componentDidMount = async () => {
      await Promise.all([
        this.fetchTags(),
        this.props.fetchAllergens(),
        this.props.fetchKitchens(),
        this.props.fetchDepartments(),
        this.props.fetchIngredients({
          pageSize: 30,
          pages: 1,
          filtered: [],
          sorted: [],
        }),
        this.isEdit && this.getRecipe(),
      ]);

      this.setState({ isLoading: false });
    };

    render() {
      return (
        <WrappedComponent
          commonWotValue={this.state.commonWotValue}
          defaultWorker={this.state.defaultWorker}
          departmentState={this.state.departmentState}
          isCommonWot={this.state.isCommonWot}
          isLoading={this.state.isLoading}
          kitchenImageStateUrl={this.state.kitchenImageStateUrl}
          kitchenState={this.state.kitchenState}
          nameForClient={this.state.nameForClient}
          recipeState={this.state.recipeState}
          selectedTags={this.state.selectedTags}
          tags={this.state.tags}
          type={this.state.type}
          userIngredients={this.state.userIngredients}
          workNameState={this.state.workNameState}
          recipeId={this.recipeId}
          filter={this.filterIngredients}
          saveToLs={this.saveToLs}
          getImage={this.getImage}
          handleWpOM={this.handleWpOM}
          removeImage={this.removeImage}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          getAllergens={this.getAllergens()}
          addIngredient={this.addIngredient}
          selectEmployee={this.selectEmployee}
          handleQuantity={this.handleQuantity}
          handleTagChange={this.handleTagChange}
          handleUserValue={this.handleUserValue}
          removeIngredient={this.removeIngredient}
          handleThermalProcessing={this.handleThermalProcessing}
          handleWorkingOnMachining={this.handleWorkingOnMachining}
          isEdit={this.isEdit}
          handleClickCommonWot={this.handleClickCommonWot}
          handleChangeCommonWotValue={this.handleChangeCommonWotValue}
        />
      );
    }
  };

const mapStateToProps = state => ({
  dishIngredientsDecimalPlaces:
    state?.Company?.dietarySetup?.dishIngredientsDecimalPlaces,
});

const mapDispatchToProps = {
  fetchIngredient,
  fetchIngredients,
  fetchAllergens,
  fetchDepartments,
  fetchKitchens,
};

export default compose(
  withToast,
  withHooks,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  RecipesContainer
);
