import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import ColorPicker from 'components/BlockColorPicker/BlockColorPicker';
import CustomInput from 'components/CustomInput/CustomInput';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';
import CardWrapper from 'components/Card/CardWrapper';
import { makeStyles } from '@material-ui/styles';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import Select from 'react-select';

import classNames from 'classnames';
const useStyles = makeStyles({
  tooltip: {
    marginLeft: '10px',
  },
  icon: {
    color: 'RGB(128,128,128)',
  },
  cardBody: {
    paddingBottom: '3rem',
  },
  invoiceOverrideName: {
    margin: '0 -15px',
  },
  gridItem: { marginLeft: '-15px' },
  formLabel: { marginBottom: '3rem' },
});

const InvoiceOverrideTooltip = () => {
  const { t } = useTranslation();
  return (
    <InfoIconTooltip
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              'views.diets.basicInfo.form.field.invoiceOverride.tooltip',
              { interpolation: { escapeValue: false } }
            ),
          }}
        ></div>
      }
    />
  );
};

const BasicInfo = ({
  classes: propClasses,
  t,
  handleInputChange,
  state,
  handleToggle,
  handleColorPicker,
  selectedCategories,
  setSelectedCategories,
  categories,
}) => {
  const classes = useStyles();
  return (
    <CardWrapper title={t('form.basicInfo')}>
      <CardBody className={classes.cardBody}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <FormTextInput
              label={t('form.field.dietName') + '*'}
              classes={propClasses}
              name="name"
              value={state.name}
              handleChange={handleInputChange}
              inputSize={8}
            />
            <FormTextInput
              label={t('form.field.workingName')}
              classes={propClasses}
              name="workName"
              value={state.workName}
              handleChange={handleInputChange}
              inputSize={8}
            />
            <GridContainer>
              <GridItem sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      name="invoiceOverride"
                      onClick={() =>
                        handleInputChange(
                          { target: { name: 'invoiceOverride' } },
                          !state.invoiceOverride
                        )
                      }
                      checked={state.invoiceOverride}
                      checkedIcon={
                        <Check className={propClasses.checkedIcon} />
                      }
                      icon={<Check className={propClasses.uncheckedIcon} />}
                      classes={{
                        checked: propClasses.checked,
                        root: propClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: propClasses.label,
                  }}
                  label={
                    <>
                      {state.invoiceOverride && (
                        <GridItem
                          sm={12}
                          className={classes.invoiceOverrideName}
                        >
                          <FormLabel className={propClasses.labelHorizontal}>
                            {t(
                              'views.diets.basicInfo.form.field.invoiceOverride.label'
                            )}{' '}
                            *
                            <InvoiceOverrideTooltip />
                          </FormLabel>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              name: 'invoiceOverrideName',
                              value: state.invoiceOverrideName,
                              onChange: handleInputChange,
                            }}
                          />
                        </GridItem>
                      )}
                      {!state.invoiceOverride && (
                        <>
                          {t(
                            'views.diets.basicInfo.form.field.invoiceOverride.label'
                          )}
                          <InvoiceOverrideTooltip />
                        </>
                      )}
                    </>
                  }
                />
              </GridItem>
            </GridContainer>

            <GridItem md={12} className={classes.gridItem}>
              <div>
                {t('form.field.dietType')}*
                <Tooltip
                  className={classes.tooltip}
                  title={
                    <div>
                      <h4>{t('form.field.dietType.tooltip')}</h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info className={classes.icon} fontSize={'small'} />
                </Tooltip>
              </div>
              <ReactSelect
                Component={Select}
                selectedValues={selectedCategories}
                options={categories}
                noOptionsMessage={t(
                  'form.field.dietSelect.noOptionsMessage',
                  'Nie znaleziono szukanego typu diety'
                )}
                handleChange={e => {
                  setSelectedCategories(e);
                }}
                placeholder=" "
              />
            </GridItem>

            <FormTextInput
              label={t('form.field.code') + '*'}
              classes={propClasses}
              name="code"
              value={state.code}
              handleChange={ev => {
                const value = ev.target.value;
                const valueTrimmed = value.replace(/\s/g, '');

                handleInputChange(ev, valueTrimmed);
              }}
              inputSize={8}
            />

            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => handleToggle('checked')}
                  checked={state.checked}
                  checkedIcon={<Check className={propClasses.checkedIcon} />}
                  icon={<Check className={propClasses.uncheckedIcon} />}
                  classes={{
                    checked: propClasses.checked,
                    root: propClasses.checkRoot,
                  }}
                />
              }
              classes={{
                label: propClasses.label,
              }}
              label={
                <>
                  {t('form.activeDiet')}
                  <div>
                    <Tooltip
                      className={classes.tooltip}
                      title={
                        <div>
                          <h4>{t('form.dietActiveInfo5.title')}</h4>
                          <h4>{t('form.dietActiveInfo5.description')}</h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize="small" />
                    </Tooltip>
                  </div>
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() =>
                    handleToggle('allowStepWithAdditionalMealTypes')
                  }
                  checked={state.allowStepWithAdditionalMealTypes}
                  checkedIcon={<Check className={propClasses.checkedIcon} />}
                  icon={<Check className={propClasses.uncheckedIcon} />}
                  classes={{
                    checked: propClasses.checked,
                    root: propClasses.checkRoot,
                  }}
                />
              }
              classes={{
                label: propClasses.label,
              }}
              label={<>{t('form.allowStepWithAdditionalMealTypes')}</>}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormLabel
              className={classNames(
                propClasses.labelHorizontal,
                classes.formLabel
              )}
            >
              {t('form.field.color')}
              <div>
                <Tooltip
                  className={classes.tooltip}
                  title={
                    <div>
                      <h4>{t('form.field.color.tooltip')}:</h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize="small" />
                </Tooltip>
              </div>
            </FormLabel>
            <ColorPicker color={state.color} handleChange={handleColorPicker} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(({ Diets: { diets }, Variants: { variants } }) => ({
    diets,
    variants,
  })),
  withTranslation(),
  withStyles(combinedStyles)
);

export default enhance(BasicInfo);
