import Moment from 'moment';
import { currencyToSymbol } from 'utils/currencies';

import DateRangeFilter from 'components/Grid/Filter/DateRange';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: true,
    width: 50,
  },
  {
    title: 'list.nameForClient',
    name: 'nameForClient',
    accessor: 'nameForClient',
    filterable: true,
  },
  {
    title: 'list.workingName',
    name: 'workName',
    accessor: 'workName',
    filterable: true,
  },
  {
    title: 'list.ingredients',
    name: 'ingredients.ingredient.workName',
    accessor: row =>
      row.ingredients
        .map(ingredient => ingredient.ingredient.workName)
        .join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.allergens',
    name: 'ingredients.ingredient.allergens.value',
    accessor: row => row.allergens.map(allergen => allergen.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.creationDate',
    accessor: row => new Moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    name: 'createdAt',
    Filter: ({ onChange, filter }) => (
      <DateRangeFilter onChange={onChange} value={filter?.value} />
    ),
    width: 160,
  },
  {
    title: 'list.tags',
    name: 'tags.value',
    accessor: row => row.tags.map(tag => tag.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.recipes.type',
    name: 'type',
    accessor: row => {
      if (row?.type === 'WEIGHT') return t('form.field.type.WEIGHT');
      if (row?.type === 'PIECE') return t('form.field.type.PIECE');
      return null;
    },
    Filter: ({ onChange, filter }) => {
      return (
        <select
          style={{ height: '30px', width: '100%' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value="">{t('common.lack', 'Brak')}</option>
          <option value="WEIGHT">{t('form.field.type.WEIGHT')}</option>
          <option value="PIECE">{t('form.field.type.PIECE')}</option>
        </select>
      );
    },
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.price',
    name: 'price',
    accessor: (row, plug) => {
      return row.price
        ? `${row.price.toFixed(2)} ${currencyToSymbol(
            plug.multinational.currency
          )}`
        : '';
    },
    filterable: false,
    sortable: true,
  },
];

export default columnConfig;
