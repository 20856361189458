import { withToast } from 'material-ui-toast-redux';
import { get, post, put } from 'helpers/apiHelpers';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import CardWrapper from 'components/Card/CardWrapper';
import { Divider } from '@material-ui/core';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Button from 'components/CustomButtons/Button.jsx';
import { Close } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import TOAST_DURATIONS from 'helpers/toastDurations';

const useStyles = makeStyles({
  newBiologicalButton: {
    marginBottom: '16px',
    padding: '20px',
    width: '100%',
    background: '#FAFAFA',
    color: 'black',
    borderTop: '2px solid #EFEFEF',
    borderBottom: '2px solid #EFEFEF',
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  closeButton: {
    background: 'white',
    boxShadow: 'none',
  },
  closeIcon: { color: 'red', cursor: 'pointer' },
  margin: { marginBotto: '10px' },
  flex: { display: 'flex' },
});
const GroupOfFinishedProductsForm = ({
  match,
  location,
  history,
  openToast,
  classes: propClasses,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: '',
    consumerGroup: '',
    container: '',
    biologicalRequirements: [
      {
        strain: '',
        result: '',
        method: '',
      },
    ],
  });
  const classes = useStyles();

  const isEdit = location.pathname.includes('edit');
  const groupId = match.params.id;

  useEffect(() => {
    console.log('nie powinno');
    if (isEdit) {
      get(`/kwg-groups/${groupId}`).then(res => {
        setState({
          name: res.name,
          consumerGroup: res.consumerGroup,
          container: res.container,
          biologicalRequirements: res.biologicalRequirements,
        });
      });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const regex = /^[^\s][\s\S]*$/;

    if (!regex.test(state.name)) {
      openToast({
        messages: [t('form.groupOfFinishedProductsForm.validation.name.info')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return;
    }

    if (state.name.length === 0) {
      openToast({
        messages: [t('form.validation.info')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
      return;
    }

    const data = {
      name: state.name,
      consumerGroup: state.consumerGroup,
      container: state.container,
      biologicalRequirements: state.biologicalRequirements,
    };

    const action = isEdit
      ? put(`/kwg-groups/${groupId}`, data)
      : post('/kwg-groups', data);

    action.then(() => {
      history.push('/admin/meals/kwg_group');

      openToast({
        messages: [t('form.kwgGroup.add.success')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    });
  };

  const handleChange = e => {
    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeBiological = (e, changedItemIndex) => {
    setState(prev => ({
      ...prev,
      biologicalRequirements: prev.biologicalRequirements.map((item, index) => {
        if (index === changedItemIndex) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        }
        return item;
      }),
    }));
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('form.characteristicsFinishedProduct')}>
            <CardBody>
              <GridItem xs={4}>
                <FormTextInput
                  label={t('views.nameOfFinishedProductGroup') + '*'}
                  name="name"
                  value={state.name}
                  classes={propClasses}
                  inputSize={12}
                  maxLength={255}
                  handleChange={handleChange}
                />
              </GridItem>
              <GridItem xs={4}>
                <FormTextInput
                  label={t('views.nameOfConsumersGroup')}
                  name="consumerGroup"
                  value={state.consumerGroup}
                  classes={propClasses}
                  inputSize={12}
                  maxLength={255}
                  handleChange={handleChange}
                />
              </GridItem>
              <GridItem xs={4}>
                <FormTextInput
                  label={t('views.unitPacking')}
                  name="container"
                  value={state.container}
                  classes={propClasses}
                  inputSize={12}
                  maxLength={255}
                  handleChange={handleChange}
                />
              </GridItem>
            </CardBody>
          </CardWrapper>
          <CardWrapper title={t('form.microbiologicalRequirements')}>
            <CardBody>
              <GridContainer className={classes.margin}>
                <GridItem xs={1}>{t('form.kwg.number')}</GridItem>
                <GridItem xs={4}>{t('form.kwg.strainTested')}</GridItem>
                <GridItem xs={3}>{t('form.kwg.result')}</GridItem>
                <GridItem xs={4}>{t('form.kwg.methodology')}</GridItem>
              </GridContainer>
              <Divider />
              {state.biologicalRequirements?.map((item, index) => {
                return (
                  <GridContainer key={index}>
                    <GridItem
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {index + 1}
                    </GridItem>
                    <GridItem xs={4}>
                      <FormTextInput
                        placeholder={t('form.kwg.strainTested')}
                        name="strain"
                        value={item.strain}
                        classes={propClasses}
                        inputSize={12}
                        maxLength={255}
                        handleChange={e => handleChangeBiological(e, index)}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <FormTextInput
                        placeholder={t('form.kwg.result')}
                        name="result"
                        value={item.result}
                        classes={propClasses}
                        inputSize={12}
                        maxLength={255}
                        handleChange={e => handleChangeBiological(e, index)}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <div className={classes.flex}>
                        <FormTextInput
                          placeholder={t('form.kwg.methodology')}
                          name="method"
                          value={item.method}
                          classes={propClasses}
                          inputSize={12}
                          maxLength={255}
                          handleChange={e => handleChangeBiological(e, index)}
                        />
                        <Button
                          onClick={() => {
                            setState(prev => ({
                              ...prev,
                              biologicalRequirements:
                                prev.biologicalRequirements.filter(
                                  (_, i) => i !== index
                                ),
                            }));
                          }}
                          className={classes.closeButton}
                        >
                          <Close className={classes.closeIcon} />
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                );
              })}
              <GridItem xs={12}>
                <Button
                  onClick={() => {
                    setState(prev => ({
                      ...prev,
                      biologicalRequirements: [
                        ...prev.biologicalRequirements,
                        {
                          strain: '',
                          result: '',
                          method: '',
                        },
                      ],
                    }));
                  }}
                  className={classes.newBiologicalButton}
                >
                  + {t('form.addValue')}
                </Button>
              </GridItem>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>
      <FormControlStickyButton
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/meals/kwg_group"
        handleSubmit={handleSubmit}
        customOffsetSmall={'10px'}
        customOffsetLarge={'10px'}
        isFixedToBottom={true}
      />
    </>
  );
};

export default withToast(
  withStyles(extendedFormsStyle)(GroupOfFinishedProductsForm)
);
