import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import { combineStyles, isGranted } from 'helpers/helpers';
import roles from 'helpers/roles';
import NavTabs from 'components/NavTabs/NavTabs';
import Button from 'components/CustomButtons/Button';

import NotesRecipeForm from './NotesRecipeForm';
import RegularRecipeForm from './RegularRecipeForm';

const theme = createMuiTheme({ typography: { useNextVariants: true } });

const RecipeForm = ({
  isLoading,
  isEdit,
  selectedBrand,
  saveToLs,
  tags,
  type,
  filter,
  classes,
  recipeId,
  getImage,
  handleWpOM,
  removeImage,
  recipeState,
  handleSubmit,
  getAllergens,
  selectedTags,
  handleChange,
  workNameState,
  addIngredient,
  defaultWorker,
  selectEmployee,
  handleQuantity,
  handleUserValue,
  handleTagChange,
  userIngredients,
  removeIngredient,
  kitchenImageStateUrl,
  handleThermalProcessing,
  handleWorkingOnMachining,
  departmentState,
  kitchenState,
  commonWotValue,
  isCommonWot,
  handleClickCommonWot,
  handleChangeCommonWotValue,
  nameForClient,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ opacity: isLoading ? 0 : 1, transition: '0.5s all' }}>
      <ThemeProvider theme={theme}>
        <NavTabs
          title={recipeId ? t('form.editRecipe') : t('form.recipeCreation')}
          tabs={[
            {
              tabButton: t('common.regular'),
              tabContent: (
                <RegularRecipeForm
                  tags={tags}
                  type={type}
                  filter={filter}
                  classes={classes}
                  recipeId={recipeId}
                  getImage={getImage}
                  handleWpOM={handleWpOM}
                  removeImage={removeImage}
                  recipeState={recipeState}
                  handleSubmit={handleSubmit}
                  getAllergens={getAllergens}
                  selectedTags={selectedTags}
                  handleChange={handleChange}
                  workNameState={workNameState}
                  addIngredient={addIngredient}
                  defaultWorker={defaultWorker}
                  selectEmployee={selectEmployee}
                  handleQuantity={handleQuantity}
                  handleUserValue={handleUserValue}
                  handleTagChange={handleTagChange}
                  userIngredients={userIngredients}
                  removeIngredient={removeIngredient}
                  kitchenImageStateUrl={kitchenImageStateUrl}
                  handleThermalProcessing={handleThermalProcessing}
                  handleWorkingOnMachining={handleWorkingOnMachining}
                  departmentState={departmentState}
                  kitchenState={kitchenState}
                  commonWotValue={commonWotValue}
                  isCommonWot={isCommonWot}
                  handleClickCommonWot={handleClickCommonWot}
                  handleChangeCommonWotValue={handleChangeCommonWotValue}
                  nameForClient={nameForClient}
                />
              ),
            },
            ...(isEdit && isGranted(roles.ROLE_NOTE_RECIPE)
              ? [
                  {
                    tabButton: t('common.notes'),
                    tabContent: <NotesRecipeForm recipeId={recipeId} />,
                  },
                ]
              : []),
          ]}
        >
          {isEdit && (
            <Link
              to={`/admin/recipes/add/?savedRecipe&selectedBrand=${selectedBrand}`}
              target="_blank"
            >
              <Button
                color="primary"
                variant="contained"
                size="md"
                onClick={saveToLs}
                className={classes.button}
              >
                {t('form.copyToNewRecipe')}
              </Button>
            </Link>
          )}
        </NavTabs>
      </ThemeProvider>
    </div>
  );
};

const styles = {
  button: { margin: 'unset' },
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle,
  styles
);

const mapStateToProps = state => ({
  selectedBrand: state.Auth.selectedBrand,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(withRouter(RecipeForm)));
