import { DialogLoader } from 'components/DialogLoader';
import Datetime from 'react-datetime';
import AdminTable from 'layouts/AdminTable';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  SelectContainer,
  DriversTablesContainer,
  routeManagerStyles,
} from './RouteManager.styles';
import { isDatepickerValidDay } from '../../helpers/dateHelpers';
import moment from 'moment';
import { get, put } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from 'helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { DragDropContext } from 'react-beautiful-dnd';
import Table from './Table';
import { ROLE_EDIT_LOGISTIC_ROUTE_MANAGER } from 'helpers/roles';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';
import { fetchBrand } from '../../actions/Brands';
import Clocks from './Clocks';
import Button from '../../components/CustomButtons/Button';
import { withToast } from 'material-ui-toast-redux';
import TOAST_DURATIONS from 'helpers/toastDurations';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const RouteManager = ({ t, classes, selectedBrand, fetchBrand, openToast }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliveryTypeId, setSelectedDeliveryTypeId] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [remainingTimes, setRemainingTimes] = useState({});

  const [deliveryTypes, setDeliveryTypes] = useState(null);

  const selectedDrivers = useMemo(
    () => drivers?.filter(driver => driver.isChecked),
    [drivers]
  );

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      const response = await Promise.all([
        fetchBrand(selectedBrand),
        get('/delivery-types', { pagination: false }),
        get(`drivers?pagination=false`),
      ]);

      response.forEach((res, index) => {
        switch (index) {
          case 0:
            setRemainingTimes(
              days.reduce((days, day) => ({ ...days, [day]: res[day] }), {})
            );
            break;

          case 1:
            setDeliveryTypes(res['hydra:member']);
            break;

          case 2:
            setDrivers(
              res['hydra:member'].map(driver => ({
                ...driver,
                isChecked: false,
                routes: [],
              }))
            );
            break;

          default:
            return;
        }
      });

      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDrivers.length && selectedDate && selectedDeliveryTypeId) {
      setIsLoading(true);
      setRoutes([]);

      const fetchData = async () => {
        const response = await Promise.all(
          selectedDrivers.map(driver =>
            get(
              `route-points/${driver.id}/${selectedDate}?deliveryTypeId=${selectedDeliveryTypeId}`
            )
          )
        );

        setRoutes(
          response.map((route, index) => {
            const sortedRoutes = route['hydra:member'].sort(
              (a, b) => a.priority - b.priority
            );

            return {
              ...route,
              'hydra:member': sortedRoutes,
              driverId: selectedDrivers[index].id,
            };
          })
        );

        setIsLoading(false);
      };

      fetchData();
    }
  }, [selectedDate, selectedDeliveryTypeId, selectedDrivers]);

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const addressesWithDriverIds = routes.reduce((routePoints, point) => {
        const unSortedRoutes = point['hydra:member'].filter(
          address => address.priority === null
        );

        const sortedRoutes = point['hydra:member']
          .filter(address => address.priority !== null)
          .map((address, index) => {
            return { ...address, priority: index + 1 };
          });

        const routeIdsWithDriverIds = [...unSortedRoutes, ...sortedRoutes].map(
          route => ({
            address: `/addresses/${route.id}`,
            driver: `/drivers/${point.driverId}`,
            priority: route.priority,
          })
        );

        return [...routePoints, ...routeIdsWithDriverIds];
      }, []);

      await put('/route-points/update/driver', {
        collection: addressesWithDriverIds,
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: TOAST_DURATIONS.SM,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleToggle = id => {
    setDrivers(prev =>
      prev.map(driver =>
        driver.id === id ? { ...driver, isChecked: !driver.isChecked } : driver
      )
    );
  };

  const onDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) {
      return;
    }

    const startRoutes = routes.find(
      route => route.driverId === source.droppableId
    );

    const finishRoutes = routes.find(
      route => route.driverId === destination.droppableId
    );

    const movedRoute = startRoutes['hydra:member'].find(
      route => +route.id === +draggableId
    );

    movedRoute.priority = 0;

    const newStartRoutes = startRoutes['hydra:member'].reduce(
      (routes, route, index) => {
        if (index !== source.index) return [...routes, route];
        else return routes;
      },
      []
    );

    if (destination.droppableId === source.droppableId) {
      newStartRoutes.splice(destination.index, 0, movedRoute);

      setRoutes(prev =>
        prev.map(route => {
          if (route.driverId === finishRoutes.driverId)
            return { ...route, 'hydra:member': newStartRoutes };
          else return route;
        })
      );
    } else {
      const newFinishRoutes = Array.from(finishRoutes['hydra:member']);
      newFinishRoutes.splice(destination.index, 0, movedRoute);

      setRoutes(prev =>
        prev.map(route => {
          if (route.driverId === finishRoutes.driverId)
            return { ...route, 'hydra:member': newFinishRoutes };
          else if (route.driverId === startRoutes.driverId)
            return { ...route, 'hydra:member': newStartRoutes };
          else return route;
        })
      );
    }
  };

  const areParametersSelected = selectedDate && selectedDeliveryTypeId;

  const driversStyles = {
    opacity: areParametersSelected ? 1 : 0.4,
    pointerEvents: areParametersSelected ? 'auto' : 'none',
  };

  return (
    <Fragment>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />
      <DialogLoader loaderState={isLoading} text={t('common.loading')} />

      <AdminTable
        title={t('routeManager.firstSection.header')}
        isMarginLeft={true}
      >
        {deliveryTypes && (
          <SelectContainer>
            <div>
              <p>{t('common.shared.selectDate')}</p>
              <Datetime
                isValidDate={isDatepickerValidDay}
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={selectedDate}
                onChange={e => setSelectedDate(e.format('YYYY-MM-DD'))}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div>
              <p>{t('reports.selectDeliveryType')}</p>
              <SelectInput
                classes={classes}
                mapBy="systemValue"
                trackBy="id"
                value={selectedDeliveryTypeId}
                options={deliveryTypes}
                handleChange={e => setSelectedDeliveryTypeId(e.target.value)}
                name="deliveryType"
                id="deliveryType"
              />
            </div>
          </SelectContainer>
        )}
        <p>{t('routeManager.firstSection.table.header')}</p>
        <Clocks {...{ selectedDate, remainingTimes }} />
      </AdminTable>

      <AdminTable
        title={t('routeManager.secondSection.header')}
        isMarginLeft={true}
      >
        {!!drivers.length && (
          <GridContainer>
            <GridItem md={3}>
              <p className={classes.driversLabel}>
                {t('routeManager.secondSection.label')}
                {!areParametersSelected && (
                  <InfoIconTooltip title={t('routeManager.tooltip')} />
                )}
              </p>
              <div style={driversStyles} className={classes.drivers}>
                {drivers?.map(driver => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => handleToggle(driver.id)}
                        checked={
                          drivers.find(elem => elem.id === driver.id).isChecked
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={driver.name}
                  />
                ))}
              </div>
            </GridItem>
            <GridItem md={9}>
              {!!selectedDrivers.length && !!routes.length && !isLoading && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <DriversTablesContainer>
                    {routes.map((route, index) => (
                      <Table
                        key={route['@id']}
                        driver={selectedDrivers[index]}
                        routes={route['hydra:member']}
                        isDragDisabled={
                          !isGranted(ROLE_EDIT_LOGISTIC_ROUTE_MANAGER)
                        }
                      />
                    ))}
                  </DriversTablesContainer>
                </DragDropContext>
              )}
            </GridItem>
          </GridContainer>
        )}
      </AdminTable>
      {!isLoading && (
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              onClick={onSubmit}
              disabled={!isGranted(ROLE_EDIT_LOGISTIC_ROUTE_MANAGER)}
              color={'success'}
              round
            >
              {t('zones.save')}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, routeManagerStyles);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand }
  ),
  withToast
);

export default enhance(RouteManager);
