import ReactSelectComponent from 'react-select';
import { useTranslation } from 'react-i18next';
import { Info } from '@material-ui/icons';
import { FormLabel, Tooltip } from '@material-ui/core/index';
import isEmpty from 'lodash/isEmpty';

import ReactSelect from 'components/ReactSelect/ReactSelect';
import Button from 'components/CustomButtons/Button.jsx';
import { useState } from 'react';

const CopyMealToMeals = ({
  copyMealTypeFrom,
  copyMealTypeTo,
  handleCopySize,
  handleCopySizeChange,
  selectedBrandMealTypes,
}) => {
  const { t } = useTranslation();
  const [mealTypeInputValue, setMealTypeInputValue] = useState('');

  const filteredSelectedBrandMealTypes = selectedBrandMealTypes
    .map(option => {
      return {
        label: `${option.name} (${option.brandName})`,
        value: option,
      };
    })
    .filter(item => {
      if (!isEmpty(copyMealTypeFrom)) {
        return item.value['@id'] !== copyMealTypeFrom.value['@id'];
      }

      return true;
    });

  const handleChange = stateKey => (obj, option) => {
    if (stateKey === 'copyMealTypeFrom') {
      return handleCopySizeChange(stateKey, obj);
    }

    if (option.action === 'clear') {
      setMealTypeInputValue('');
      return handleCopySizeChange(stateKey, []);
    }

    if (stateKey === 'copyMealTypeTo' && option.action !== 'pop-value') {
      const hasSelectAll = obj.findIndex(({ value }) => value === '*') !== -1;
      const hasUnSelectAll =
        obj.findIndex(({ value }) => value === '-*') !== -1;

      let options = [];
      if (hasSelectAll) {
        options = filteredSelectedBrandMealTypes;
      }

      if (hasUnSelectAll) {
        options = [];
      }

      if (!hasSelectAll && !hasUnSelectAll) {
        options = obj;
      }

      return handleCopySizeChange(stateKey, options);
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr auto auto',
        gap: '10px',
        alignItems: 'end',
        paddingBottom: '15px',
      }}
    >
      <div>
        <FormLabel>
          {t('mealTypes.copyFromMeal', '$$Kopiuj z posiłku')}
        </FormLabel>
        <ReactSelect
          defaultValue={[]}
          isMulti={false}
          selectedValues={copyMealTypeFrom}
          options={selectedBrandMealTypes.map(option => {
            return {
              label: `${option.name} (${option.brandName})`,
              value: option,
            };
          })}
          handleChange={handleChange('copyMealTypeFrom')}
          placeholder=" "
          noOptionsMessage={t(
            '$*noSearchResults',
            '$$Brak wyników wyszukiwania'
          )}
          Component={ReactSelectComponent}
          isDisabled={!selectedBrandMealTypes.length}
        />
      </div>

      <div>
        <FormLabel>
          {t('mealTypes.copyToOtherMeal', '$$Kopiuj do posiłków')}
        </FormLabel>
        <ReactSelect
          closeMenuOnSelect={false}
          inputValue={mealTypeInputValue}
          selectedValues={copyMealTypeTo}
          onInputChange={(value, action) => {
            if (action.action === 'input-change') {
              setMealTypeInputValue(value);
            }
          }}
          options={[
            copyMealTypeTo.length === filteredSelectedBrandMealTypes.length
              ? {
                  label: t('$*common.unSelectAll', '$$Odznacz wszystko'),
                  value: '-*',
                }
              : {
                  label: t('$*common.selectAll', '$$Zaznacz wszystko'),
                  value: '*',
                },
            ...filteredSelectedBrandMealTypes.filter(
              ({ value }) =>
                !copyMealTypeTo
                  .map(({ value }) => value['@id'])
                  .includes(value['@id'])
            ),
          ]}
          handleChange={handleChange('copyMealTypeTo')}
          filterOption={(option, searchText) => {
            return option.label
              .toLowerCase()
              .includes(searchText.toLowerCase());
          }}
          placeholder=" "
          noOptionsMessage={t(
            '$*noSearchResults',
            '$$Brak wyników wyszukiwania'
          )}
          Component={ReactSelectComponent}
          isDisabled={
            !filteredSelectedBrandMealTypes.length || isEmpty(copyMealTypeFrom)
          }
        />
      </div>

      <Button
        color="primary"
        variant="contained"
        onClick={handleCopySize}
        disabled={isEmpty(copyMealTypeFrom) || isEmpty(copyMealTypeTo)}
        style={{ margin: 0 }}
      >
        {t('common.copy', 'Skopiuj')}
      </Button>
      <Tooltip
        title={
          <h4
            dangerouslySetInnerHTML={{
              __html: t(
                'mealTypes.copyRulesInfo',
                'Skopiowane zostaną wartości składników z wielkości o tych samych <b>wymaganych kalorycznościach</b>. np. Śniadanie S (200kcal) można skopiować do II Śniadania XS (200kcal).<br/><br/>Jeśli wielkość do której chcemy kopiować nie będzie obecna w wielkości, z której kopiujemy, to wartości nie zostaną zmienione.'
              ),
            }}
          />
        }
        placement="right"
      >
        <Info
          fontSize={'small'}
          style={{
            color: 'grey',
            marginLeft: '4px',
          }}
        />
      </Tooltip>
    </div>
  );
};

export default CopyMealToMeals;
