import React from 'react';
import isEmpty from 'lodash/isEmpty';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

const defaults = {
  trackBy: '@id',
  mapBy: 'value',
  multiple: false,
};

const prepareSingle = (value, trackBy) => {
  if (value === undefined) return null;

  if (value !== null && value.hasOwnProperty(trackBy)) {
    return value[trackBy];
  }

  return value;
};

const prepareMultiple = (value, trackBy) => {
  if (value === undefined) return null;

  return value.map(el => {
    if (el.hasOwnProperty(trackBy)) {
      return el[trackBy];
    }

    return el;
  });
};

const handleSingle = (options, trackBy, ev, handleChange) => {
  let selectedValue = options.find(el => {
    return el[trackBy] === ev.target.value;
  });

  handleChange(ev, selectedValue);
};

const handleMultiple = (options, trackBy, ev, handleChange) => {
  const selectedValues = options.filter(option => {
    return ev.target.value.includes(option[trackBy]);
  });

  handleChange(ev, selectedValues);
};

const SelectInput = props => {
  const {
    dataCy = '',
    label,
    name,
    id,
    classes,
    options,
    value,
    handleChange,
    mapBy,
    disabled,
    trackBy,
    tooltip,
    size,
    multiple,
    customStyle,
    disabledMenuItems,
    noGrid,
    customLabel,
    className,
    customSelectClasses = {},
    customFormControlClasses = {},
  } = { ...defaults, ...props };

  const defaultValue = multiple
    ? prepareMultiple(value, trackBy)
    : prepareSingle(value, trackBy);

  const handleSelectChange = ev =>
    multiple
      ? handleMultiple(options, trackBy, ev, handleChange)
      : handleSingle(options, trackBy, ev, handleChange);

  if (noGrid) {
    return (
      <FormControl
        fullWidth
        className={
          isEmpty(customFormControlClasses)
            ? classes.selectFormControl
            : customFormControlClasses.customSelectFormControl
        }
      >
        <Select
          data-cy={dataCy}
          className={className}
          multiple={multiple}
          MenuProps={{ className: classes.selectMenu }}
          // style={customStyle}
          classes={{
            select: classes.select,
            ...customSelectClasses,
          }}
          value={defaultValue}
          onChange={handleSelectChange}
          name={name}
          inputProps={{
            name: name,
            id: id,
            disabled: disabled ? disabled : false,
          }}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                className={`${className}__item`}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value={option[trackBy]}
                disabled={
                  (disabledMenuItems &&
                    disabledMenuItems.some(
                      item => item[trackBy] === option[trackBy]
                    )) ||
                  false
                }
              >
                {customLabel ? customLabel(option) : option[mapBy]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return (
    <GridContainer alignItems="center">
      <GridItem xs={size ? size : 12}>
        <FormLabel className={classes.labelHorizontal}>
          {label} {tooltip && <InfoIconTooltip title={tooltip} />}
        </FormLabel>
        <FormControl
          fullWidth
          className={
            isEmpty(customFormControlClasses)
              ? classes.selectFormControl
              : customFormControlClasses.customSelectFormControl
          }
        >
          <Select
            className={className}
            multiple={multiple}
            MenuProps={{ className: classes.selectMenu }}
            style={customStyle}
            classes={{
              select: classes.select,
              ...customSelectClasses,
            }}
            value={defaultValue}
            onChange={handleSelectChange}
            name={name}
            inputProps={{
              name: name,
              id: id,
              disabled: disabled ? disabled : false,
            }}
          >
            {options?.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  className={`${className}__item`}
                  disabled={
                    (disabledMenuItems &&
                      disabledMenuItems.some(
                        item => item[trackBy] === option[trackBy]
                      )) ||
                    false
                  }
                  style={{ zIndex: 70000 }}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple,
                  }}
                  value={option[trackBy]}
                >
                  {customLabel ? customLabel(option) : option[mapBy]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
};

export default SelectInput;
