const getSelectSubBrandLabel = (subBrand, subBrandOptions) => {
  const fullBrand = subBrandOptions.find(
    brand => brand['@id'] === subBrand.brand
  );
  const fullBrandName = fullBrand?.name;
  const subBrandName = subBrand.name;

  return fullBrandName ? `${subBrandName} (${fullBrandName})` : subBrandName;
};

export default getSelectSubBrandLabel;
