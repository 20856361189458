import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';

import useTranslatableSelectedLanguage, {
  TranslatableObjectKey,
  useGetCompanyDefaultLanguage,
} from 'hooks/redux/Translatable/useTranslatableLanguages';
import Danger from 'components/Typography/Danger.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import LanguagePicker from 'components/Translatable/LanguagePicker';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

const FormTextInputTranslatable = ({
  label,
  classes,
  success,
  error,
  helpText,
  handleChange,
  inputSize,
  name,
  value,
  tooltip,
  maxLength,
  noAutoComplete,
  errors = {},
  setDefaultLanguage = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const [controlledVal, setControlledVal] = useState(
    typeof value === 'string'
      ? { [TranslatableObjectKey]: { pl: value } }
      : value
  );
  const selectedLanguage = useTranslatableSelectedLanguage();
  const companyDefaultLanguage = useGetCompanyDefaultLanguage();

  const errorMessage = errors[name] || '';

  useEffect(() => {
    const tmpControlledValue =
      typeof value === 'string'
        ? { [TranslatableObjectKey]: { pl: value } }
        : value;
    if (JSON.stringify(controlledVal) !== JSON.stringify(tmpControlledValue)) {
      setControlledVal(tmpControlledValue);
    }
  }, [value]);

  useEffect(() => {
    handleChange(
      { target: { name: name, value: controlledVal } },
      controlledVal
    );
    setDefaultLanguage(companyDefaultLanguage);
  }, [controlledVal, companyDefaultLanguage]);

  const currentValue = useMemo(
    () => controlledVal?.[TranslatableObjectKey]?.[selectedLanguage] ?? '',
    [controlledVal, selectedLanguage]
  );

  const defaultValue = useMemo(
    () =>
      controlledVal?.[TranslatableObjectKey]?.[companyDefaultLanguage] ?? '',
    [controlledVal, companyDefaultLanguage]
  );

  const handleTranslatableChange = useCallback(
    ({ target: { value } }) => {
      setControlledVal(prevVal => ({
        [TranslatableObjectKey]: {
          ...(prevVal[TranslatableObjectKey] ?? {}),
          [selectedLanguage]: value,
        },
      }));
    },
    [selectedLanguage]
  );

  return (
    <GridContainer alignItems="center">
      <GridItem xs={12} sm={inputSize}>
        <GridContainer alignItems="center" spacing={0}>
          <GridItem xs={12}>
            <FormLabel className={classes.labelHorizontal}>
              {label} {tooltip && <InfoIconTooltip title={tooltip} />}
            </FormLabel>
            <CustomInput
              success={success}
              error={error}
              required={true}
              helpText={helpText}
              maxLength={maxLength}
              formControlProps={{
                fullWidth: true,
              }}
              name={name}
              inputProps={{
                max: 12,
                onChange: handleTranslatableChange,
                value: currentValue,
                name,
                autoComplete: noAutoComplete ? 'new-password' : 'on',
                ...props,
                startAdornment: <LanguagePicker />,
              }}
            />
            {(!defaultValue || defaultValue === '') && (
              <Danger>
                {t('translatable.defaultLanguage.empty', {
                  replace: { defaultLanguage: companyDefaultLanguage },
                })}
              </Danger>
            )}
            {errorMessage && <Danger>{errorMessage}</Danger>}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default memo(FormTextInputTranslatable);
