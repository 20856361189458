import React, { useState } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { get } from 'helpers/apiHelpers';
import { fetchDishes } from 'actions/Dishes';

import List from '@material-ui/core/List';
import Radio from '@material-ui/core/Radio';
import Check from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Divider, ListItem, ListItemText } from '@material-ui/core';

import Button from 'components/CustomButtons/Button';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import { DialogLoader } from 'components/DialogLoader';
import { isEmpty } from 'lodash';

const ChooseFromMenuPlanningModal = ({
  date,
  classes,
  modalOpen,
  userBrands,
  fetchDishes,
  setModalOpen,
  selectInBrands,
  overProductions,
  menuPlannerOptions,
  setOverProductions,
  mealTypesFromConfig,
  transformMenuPlannerData,
}) => {
  const { t } = useTranslation();
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [checkedOptionsFromAllDiets, setCheckedOptionsFromAllDiets] = useState(
    []
  );
  const [isAddingDishes, setIsAddingDishes] = useState(false);
  const [selectDishFrom, setSelectDishFrom] = useState('menuPlanning');

  const brandsToRender = selectInBrands
    .filter(brand => brand.value !== '*')
    .map(brand => brand.label)
    .join(', ');

  const checkRow = option => {
    let newCheckedOptionsArray = [...checkedOptions];

    let result = checkedOptions.includes(option?.['@id'])
      ? newCheckedOptionsArray.filter(
          checkedOption => checkedOption !== option?.['@id']
        )
      : [...newCheckedOptionsArray, ...[option?.['@id']]];

    return setCheckedOptions(result);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCheckedOptions([]);
    setCheckedOptionsFromAllDiets([]);
  };

  const getFilteredMenuPlanners = async e => {
    try {
      const response = await get('/menu-planners', {
        pagination: false,
        sorted: [],
        'dish.nameForClient': e,
        draft: false,
        'date[after]': new Moment(date).format('YYYY-MM-DD'),
        'date[before]': new Moment(date).format('YYYY-MM-DD'),
        selectInBrands: userBrands.map(userBrand => userBrand['@id']),
      });

      return transformMenuPlannerData(response['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const getFilteredDishes = async e => {
    return fetchDishes({
      pageSize: 30,
      page: 0,
      sorted: [],
      filtered: [
        {
          id: 'name',
          value: e,
        },
        {
          id: 'selectInBrands',
          value: selectInBrands.length
            ? selectInBrands
                .map(brand => brand.value)
                .filter(val => val !== '*')
            : [],
        },
      ],
      draft: false,
      properties: ['id', 'nameForClient', 'rateAVG'],
      'properties[brands]': ['name'],
      'properties[types]': ['name'],
    });
  };

  const transformDishesToOverProductions = async (dishes, type) => {
    return await Promise.all(
      dishes.map(async dish => {
        const dishItem =
          type === 'allDishes'
            ? await get(dish['@id'], {
                'properties[sizes]': ['name', 'mealType', 'size'],
              })
            : dish;

        const randomId =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        let sizes = {};
        let prices = {};

        dishItem.sizes.forEach(dishSize => {
          const mealTypeId = dishSize.mealType['@id'] || dishSize.mealType;
          return (sizes[`${mealTypeId}_${dishSize.size}`] = dishSize['@id']);
        });

        dishItem.sizes.forEach(dishSize => {
          const mealTypeId = dishSize.mealType['@id'] || dishSize.mealType;
          return (prices[`${mealTypeId}_${dishSize.size}`] = dishSize.price);
        });

        const dishTypes = dish.types.map(type => {
          const dishType = mealTypesFromConfig.find(
            mealTypeFromConfig => mealTypeFromConfig['@id'] === type['@id']
          );

          return dishType;
        });

        return {
          temporaryId: randomId,
          dishSize: {
            dish: {
              '@id': dish['@id'],
              nameForClient: dish.nameForClient,
              sizes: sizes,
              types: dishTypes,
            },
            prices: prices,
            price: null,
          },
          mealType: null,
          size: null,
          subBrand: {
            brand: {
              '@id': '',
              name: '',
            },
            '@id': '',
          },
          total: 0,
          date: new Moment(date).format('YYYY-MM-DD'),
          errorMessage: '',
        };
      })
    );
  };

  const addSelectedDishesToOverprod = () => {
    setIsAddingDishes(true);

    return Promise.all(
      checkedOptions.map(option => {
        if (!option) return null;

        return get(option, {
          selectInBrands: selectInBrands.length
            ? selectInBrands
                .map(brand => brand.value)
                .filter(val => val !== '*')
            : [],
        });
      })
    ).then(async dishes => {
      const newOverProductions = await transformDishesToOverProductions(
        dishes,
        'selectedDishes'
      );
      closeModal();
      setIsAddingDishes(false);
      setOverProductions([...newOverProductions, ...overProductions]);
    });
  };

  const renderList = options => {
    return (
      <List>
        {options.length ? (
          <>
            <ListItem
              className={'variant-listItem'}
              onClick={() =>
                checkedOptions.length
                  ? setCheckedOptions([])
                  : setCheckedOptions(options.map(option => option['@id']))
              }
            >
              <Checkbox
                checked={checkedOptions.length}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                onChange={ev =>
                  checkedOptions.length
                    ? setCheckedOptions([])
                    : setCheckedOptions(options.map(option => option['@id']))
                }
              />
              <div>
                <ListItemText>
                  {checkedOptions.length
                    ? t('common.shared.uncheckAll')
                    : t('common.shared.checkAll')}
                </ListItemText>
              </div>
            </ListItem>
            <Divider />
          </>
        ) : (
          <ListItem>
            <div>
              <ListItemText>
                {t('common.overproduction.noDishesSelected')}
              </ListItemText>
            </div>
          </ListItem>
        )}
        {options.map(option => {
          if (!option) return <></>;

          return (
            <ListItem
              key={option['@id']}
              className={'variant-listItem'}
              onClick={() => checkRow(option)}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <Checkbox
                  checked={checkedOptions.includes(option?.['@id'])}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  onChange={ev => checkRow(option)}
                />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ListItemText>{option.nameForClient}</ListItemText>
                  <div style={{ minWidth: '200px', maxWidth: '40%' }}>
                    <p style={{ marginBottom: '0' }}>
                      {t('chooseFromMenuPlanning.occursAs', 'Występuje jako')}:{' '}
                      {(option.mealTypes ? option.mealTypes : option.types)
                        .map(mealType => `${mealType && mealType.name}`)
                        .join(', ')}
                    </p>
                    <p style={{ marginBottom: '0' }}>
                      {t(
                        'chooseFromMenuPlanning.averageGrade',
                        'Średnia ocena'
                      )}
                      :{' '}
                      {option.rateAVG
                        ? option.rateAVG.toFixed(2)
                        : t('common.shared.lack')}
                    </p>
                    <p style={{ marginBottom: '0' }}>
                      {t('chooseFromMenuPlanning.brandDish', 'Danie z marki')}:{' '}
                      {option.brands
                        ? option.brands.map(brand => brand.name).join(', ')
                        : option.brand.name}
                    </p>
                  </div>
                </div>
              </div>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open={modalOpen} onClose={closeModal}>
      <DialogLoader
        loaderState={isAddingDishes}
        text={t('common.overproduction.addingToAdditionalOrders')}
      />
      <DialogTitle>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={selectDishFrom === 'menuPlanning'}
                onChange={() => setSelectDishFrom('menuPlanning')}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('common.overproduction.fromPlanningMenu')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={selectDishFrom === 'allDishes'}
                onChange={() => setSelectDishFrom('allDishes')}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('common.overproduction.fromAllDishes')}
          />
        </div>
        <span>
          {selectDishFrom === 'menuPlanning'
            ? `${t(
                'common.overproduction.dishesFromPlanningMenuPerDay'
              )} ${new Moment(date).format('DD.MM.YYYY')}`
            : t('common.overproduction.selectDishesToAdditionalOrders')}
        </span>
      </DialogTitle>
      <DialogContent>
        <p>
          {t('common.overproduction.dishesForSelectedBrands')}:{' '}
          {brandsToRender.length
            ? brandsToRender
            : t('common.overproduction.noBrandSelected')}
        </p>
        {selectDishFrom === 'menuPlanning' ? (
          <div>
            <FormSelectDropdown
              filterName="nameForClient"
              placeholder={t('common.overproduction.selectMeal')}
              clearAfterSelect={true}
              handleClick={item => {
                if (isEmpty(item)) return;

                checkRow(item);
              }}
              filter={getFilteredMenuPlanners}
            />
            {renderList(menuPlannerOptions)}
          </div>
        ) : (
          <div style={{ minHeight: '400px' }}>
            <FormSelectDropdown
              filterName="nameForClient"
              placeholder={t('common.overproduction.selectMeal')}
              clearAfterSelect={true}
              handleClick={item => {
                if (isEmpty(item)) return;

                setCheckedOptionsFromAllDiets([
                  ...checkedOptionsFromAllDiets,
                  item,
                ]);
              }}
              filter={getFilteredDishes}
            />
            {renderList(checkedOptionsFromAllDiets)}
          </div>
        )}
      </DialogContent>
      <div
        style={{
          padding: '24px',
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ width: '15%', marginRight: '12px' }}>
          <Button color={'danger'} fullWidth onClick={closeModal}>
            {t('form.cancel')}
          </Button>
        </div>
        <div style={{ width: '15%', marginLeft: '12px' }}>
          <Button
            color={'success'}
            fullWidth
            onClick={async () => {
              if (selectDishFrom === 'menuPlanning') {
                addSelectedDishesToOverprod();
              } else {
                const filteredCheckedOptionsFromAllDiets =
                  checkedOptionsFromAllDiets.filter(optionFromAllDiets =>
                    checkedOptions.includes(optionFromAllDiets?.['@id'])
                  );

                const newOverProductions =
                  await transformDishesToOverProductions(
                    filteredCheckedOptionsFromAllDiets,
                    'allDishes'
                  );

                closeModal();
                setIsAddingDishes(false);
                setOverProductions([...newOverProductions, ...overProductions]);
              }
            }}
          >
            {t('form.addSelected')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = {
  fetchDishes,
};

export default connect(null, mapDispatchToProps)(ChooseFromMenuPlanningModal);
