import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import { remove } from 'helpers/apiHelpers';

import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from 'components/CustomButtons/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

class Actions extends Component {
  state = {
    alert: null,
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRemove = () => {
    this.props.grid.current.fireFetchData();
    if (typeof this.props.afterRemove === 'function') {
      this.props.afterRemove();
    }
  };

  confirmModal = id => {
    const { renderCustomRemoveBody } = this.props;

    if (renderCustomRemoveBody) {
      return this.setState({
        alert: (
          <SweetAlert
            warning
            title={this.props.t(
              'menuPlanner.planner.confirmModal.alertTitle',
              'Czy na pewno chcesz to zrobić?'
            )}
            onCancel={() => this.hideAlert()}
            showCancel={false}
            showConfirm={false}
          >
            {renderCustomRemoveBody({
              elementId: id,
              data: this.props.data,
              closeModal: this.hideAlert,
              removeItem: remove,
              handleAfterRemove: this.handleRemove,
            })}
          </SweetAlert>
        ),
      });
    }

    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t(
            'menuPlanner.planner.confirmModal.alertTitle',
            'Czy na pewno chcesz to zrobić?'
          )}
          onConfirm={() => {
            remove(id)
              .then(() => {
                this.handleRemove();
              })
              .catch(console.error);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes', 'Tak')}
          cancelBtnText={this.props.t('common.shared.no', 'Nie')}
          showCancel
        ></SweetAlert>
      ),
    });
  };

  render() {
    const {
      row,
      editPath,
      previewPath,
      remove,
      canEdit,
      canRemove,
      canShow,
      editAction,
      customActionsColumnDirection,
    } = this.props;

    const customActions =
      typeof this.props.customActions === 'function'
        ? this.props.customActions(row)
        : this.props.customActions;

    return (
      <React.Fragment>
        {ReactDOM.createPortal(
          this.state.alert,
          document.getElementById('portal')
        )}
        <div
          className={
            customActionsColumnDirection
              ? customActionsColumnDirection
              : 'actions-right'
          }
        >
          {canEdit &&
            (editPath ? (
              <NavLink
                to={`${editPath}/${row.id}?selectedBrand=${this.props.selectedBrand}`}
              >
                <Button
                  justIcon
                  round
                  simple
                  color="warning"
                  className="edit"
                  style={{
                    margin: '10px 5px',
                    padding: '0',
                    width: '20px',
                  }}
                >
                  <EditIcon />
                </Button>
              </NavLink>
            ) : (
              editAction && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => editAction(row, this.props.grid)}
                  color="warning"
                  className="edit"
                  style={{
                    margin: '10px 5px',
                    padding: '0',
                    width: '20px',
                  }}
                >
                  <EditIcon />
                </Button>
              )
            ))}
          {canShow && previewPath && (
            <NavLink to={`${previewPath}/${row.id}`}>
              <Button
                justIcon
                round
                simple
                color="info"
                className="preview"
                style={{
                  margin: '10px 5px',
                  padding: '0',
                  width: '20px',
                }}
              >
                <Visibility />
              </Button>
            </NavLink>
          )}
          {customActions &&
            customActions.map((action, index) => {
              if (action.type === 'customComponent') {
                const CustomComponent = action.component;
                return <CustomComponent {...this.props} />;
              }

              return (
                <Button
                  justIcon={action.justIcon}
                  key={index}
                  simple={action.simple}
                  round={action.round}
                  onClick={() => action.action(this.props)}
                  color={action.color}
                  disabled={action.disabled}
                  style={{
                    margin: '10px 5px',
                    padding: '0',
                    width: '20px',
                    ...action.style,
                  }}
                >
                  {action.buttonText && (
                    <span style={{ marginRight: '10px' }}>
                      {action.buttonText}
                    </span>
                  )}
                  {action.icon && action.icon}
                </Button>
              );
            })}
          {canRemove && remove && (
            <Button
              justIcon
              round
              simple
              onClick={() => this.confirmModal(row['@id'])}
              color="danger"
              className="remove"
              style={{
                margin: '10px 5px',
                padding: '0',
                width: '20px',
              }}
            >
              <DeleteForeverIcon />
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(sweetAlertStyle),
  withRouter,
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(Actions);
