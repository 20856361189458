import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import makeStyles from '@material-ui/styles/makeStyles';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import AdminTable from 'layouts/AdminTable';
import GridItem from 'components/Grid/GridItem';
import FormSelectMultiple from 'components/FormSelect/FormSelectMultiple';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import GridContainer from 'components/Grid/GridContainer';
import ResultDialog from './components/ResultDialog';
import TargetBrandMappingCard from './components/TargetBrandMappingCard';
import SelectInput from '../../../components/FormSelect/SelectInput';
import MenuCopyingOtherDate from './components/MenuCopyingOtherDate';
import classNames from 'classnames';
import { MAX_DAYS_COUNT } from './consts';

const useStyles = makeStyles(
  combineStyles(extendedFormsStyle, buttonsStyle, {
    headerSection: {
      marginTop: '35px',
    },
    headerDescription: {
      fontWeight: 'bold',
      color: '#6A707B',
    },
    formLabel: {
      marginBottom: '5px',
    },
    targetBrandMappingCard: {
      marginTop: '-35px',
    },
  })
);

const MenuCopyingComponent = ({
  results = [],
  isCopying = false,
  targets = [],
  sourceBrand = {},
  dateRangeTo = new Date(),
  dateRangeFrom = new Date(),
  targetBrands = [],
  brandsOptions = [],
  copyFromPublished = false,
  sourceDietOptions = [],
  sourceVariantOptions = [],
  publishMenuAfterCopy = false,
  isResultDialogOpened = false,
  sourceMealTypeOptions = [],
  isMenuOnOtherDate,
  setIsMenuOnOtherDate,
  setTargets = () => {},
  handleSubmit = () => {},
  setDateRangeTo = () => {},
  setTargetBrands = () => {},
  setDateRangeFrom = () => {},
  setIsResultDialogOpened = () => {},
  setCopyFromPublished = () => {},
  setPublishMenuAfterCopy = () => {},
  otherDateRangeFrom,
  setOtherDateRangeFrom,
  otherDateRangeTo,
  setOtherDateRangeTo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dateFormat = 'YYYY-MM-DD';

  return (
    <>
      <AdminTable
        title={t(
          '$*form.menuPlanning.menuCopyingSettings',
          '$$Ustawienia kopiowania menu'
        )}
        isMarginLeft={true}
      >
        <GridContainer className={classes.headerSection}>
          <GridItem sm={4}>
            <p className={classes.headerDescription}>
              {t('form.copyMenuPlanning.dateSection', {
                count: MAX_DAYS_COUNT,
              })}
            </p>
          </GridItem>
          <GridItem sm={8}>
            <p className={classes.headerDescription}>
              {t('form.copyMenuPlanning.otherSettings')}
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={2}>
            <FormLabel
              className={classNames(classes.labelHorizontal, classes.formLabel)}
            >
              {t('form.copyMenuPlanning.otherDays.dateFrom')}*
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(dateRangeFrom)}
                onChange={ev => {
                  const daysDifference = moment(ev).diff(
                    moment(dateRangeTo).startOf('day'),
                    'days'
                  );

                  if (daysDifference > 0) {
                    setDateRangeFrom(ev.format(dateFormat));
                    setDateRangeTo(ev.format(dateFormat));
                    return;
                  }
                  setDateRangeFrom(ev.format(dateFormat));
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem sm={2}>
            <FormLabel
              className={classNames(classes.labelHorizontal, classes.formLabel)}
            >
              {t('form.copyMenuPlanning.otherDays.dateTo')}*
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(dateRangeTo)}
                onChange={ev => setDateRangeTo(ev.format(dateFormat))}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>

          <GridItem sm={2}>
            <SelectInput
              name="copyFromPublished"
              label={
                <Tooltip
                  title={
                    <>
                      <h4>
                        {t(
                          '$*form.menuPlanning.copyFromPublished',
                          '$$Źródło do kopiowania'
                        )}
                      </h4>
                      <h6>
                        {t(
                          '$*form.menuPlanning.copyFromPublished.description',
                          '$$Zdecyduj czy chcesz kopiować opublikowane menu czy zaplanowane. Niezależnie od tego wyboru menu zawsze zostanie skopiowane na szkic do marki docelowej'
                        )}
                      </h6>
                    </>
                  }
                  placement="right"
                >
                  <div>
                    {t(
                      '$*form.menuPlanning.copyFromPublished',
                      '$$Źródło do kopiowania'
                    )}{' '}
                    <Info fontSize={'small'} />
                  </div>
                </Tooltip>
              }
              classes={classes}
              mapBy="label"
              trackBy="value"
              value={copyFromPublished}
              handleChange={(e, { value }) => {
                setCopyFromPublished(value);
                setPublishMenuAfterCopy(value);
              }}
              options={[
                {
                  label: t(
                    '$*form.menuPlanning.copyFromPublished.true',
                    '$$Opublikowane menu'
                  ),
                  value: true,
                },
                {
                  label: t(
                    '$*form.menuPlanning.copyFromPublished.false',
                    '$$Szkic menu'
                  ),
                  value: false,
                },
              ]}
            />
          </GridItem>
          <GridItem sm={2}>
            <SelectInput
              name="copyFromPublished"
              label={
                <Tooltip
                  title={
                    <>
                      <h4>
                        {t(
                          '$*form.menuPlanning.publishMenuAfterCopy',
                          '$$Opublikuj menu po skopiowaniu'
                        )}
                      </h4>
                      <h6>
                        {t(
                          '$*form.menuPlanning.publishMenuAfterCopy.description',
                          '$$Zdecyduj czy chcesz opublikować menu po zakończeniu procesu kopiowania'
                        )}
                      </h6>
                    </>
                  }
                  placement="right"
                >
                  <div>
                    {t(
                      '$*form.menuPlanning.publishMenuAfterCopy',
                      '$$Czy opublikować menu?'
                    )}{' '}
                    <Info fontSize={'small'} />
                  </div>
                </Tooltip>
              }
              classes={classes}
              mapBy="label"
              trackBy="value"
              value={publishMenuAfterCopy}
              handleChange={(e, { value }) => setPublishMenuAfterCopy(value)}
              options={[
                {
                  label: t('common.yes'),
                  value: true,
                },
                {
                  label: t('common.no'),
                  value: false,
                },
              ]}
            />
          </GridItem>
          <GridItem sm={2}>
            <FormSelectMultiple
              width="100%"
              classes={classes}
              label={
                t('$*form.menuPlanning.targetedBrands', '$$Marki docelowe') +
                ' *'
              }
              mapBy="name"
              trackBy="id"
              value={targetBrands}
              options={brandsOptions}
              handleChange={event => {
                setTargetBrands(event.target.value);
              }}
              id="selectedBrands"
              name="selectedBrands"
            />
          </GridItem>
        </GridContainer>
        <MenuCopyingOtherDate
          setIsMenuOnOtherDate={setIsMenuOnOtherDate}
          isMenuOnOtherDate={isMenuOnOtherDate}
          dateRangeTo={dateRangeTo}
          dateRangeFrom={dateRangeFrom}
          otherDateRangeFrom={otherDateRangeFrom}
          setOtherDateRangeFrom={setOtherDateRangeFrom}
          otherDateRangeTo={otherDateRangeTo}
          setOtherDateRangeTo={setOtherDateRangeTo}
        />
      </AdminTable>
      {(targetBrands ?? []).map(targetBrand => (
        <div
          className={classes.targetBrandMappingCard}
          key={targetBrand['@id']}
        >
          <TargetBrandMappingCard
            classes={classes}
            targets={targets}
            setTargets={setTargets}
            sourceBrand={sourceBrand}
            targetBrand={targetBrand}
            sourceDiets={sourceDietOptions}
            sourceVariants={sourceVariantOptions}
            sourceMealTypes={sourceMealTypeOptions}
          />
        </div>
      ))}
      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
      <ResultDialog
        isOpened={isResultDialogOpened}
        isLoading={isCopying}
        handleClose={() => setIsResultDialogOpened(false)}
        results={results}
      />
    </>
  );
};

export default MenuCopyingComponent;
