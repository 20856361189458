import React from 'react';

import Danger from 'components/Typography/Danger.jsx';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

const FormTextInput = ({
  label,
  classes,
  success,
  error,
  helpText,
  handleChange,
  multiline,
  inputSize,
  rows,
  rowsMax,
  name,
  value,
  type,
  disabled,
  noMargin,
  noGrid,
  tooltip,
  maxLength,
  noAutoComplete,
  errors = {},
  ...props
}) => {
  const errorMessage = errors[name] || false;
  return (
    <GridContainer alignItems="center">
      <GridItem xs={12} sm={inputSize}>
        <FormLabel className={classes.labelHorizontal}>
          {label} {tooltip && <InfoIconTooltip title={tooltip} />}
        </FormLabel>

        <CustomInput
          success={success}
          error={error}
          required={true}
          helpText={helpText}
          maxLength={maxLength}
          formControlProps={{
            fullWidth: true,
          }}
          name={name}
          inputProps={{
            'data-cy': `input_${name}`,
            max: 12,
            multiline: multiline,
            onChange: event => handleChange(event),
            value: value,
            type: type ? type : 'text',
            rows: rows,
            rowsMax: rowsMax,
            name: name,
            disabled: disabled ? disabled : false,
            autoComplete: noAutoComplete ? 'new-password' : 'on',
            ...props,
          }}
        />
        {errorMessage && <Danger>{errorMessage}</Danger>}
      </GridItem>
    </GridContainer>
  );
};

export default FormTextInput;
