import {
  TranslatableObjectKey,
  useTranslatableLanguages,
} from 'hooks/redux/Translatable/useTranslatableLanguages';

const useGetTranslatableObject = () => {
  const translatableLanguages = useTranslatableLanguages();

  return (
    defaultTranslatableObject = {
      [TranslatableObjectKey]: {},
    }
  ) => {
    return {
      [TranslatableObjectKey]: translatableLanguages.reduce(
        (accumulator, lang) => {
          accumulator[lang] =
            defaultTranslatableObject[TranslatableObjectKey][lang] || '';

          return accumulator;
        },
        {}
      ),
    };
  };
};

export default useGetTranslatableObject;
