import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchSubBrands = () => {
  return dispatch => {
    return get('/sub-brands', {
      pagination: false,
    }).then(response => {
      dispatch({
        type: types.FETCH_SUBBRANDS,
        payload: {
          subBrands: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};
