import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Datetime from 'react-datetime';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import {
  InputAdornment,
  InputLabel,
  Snackbar,
  SnackbarContent,
  TableFooter,
  TextField,
  useTheme,
} from '@mui/material';

import Button from 'components/CustomButtons/Button';
import Checkbox from 'components/CustomCheckbox/Checkbox';
import InfoIconTooltip from 'components/InfoIconTooltip/InfoIconTooltip';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {
  calcRecipeWeightInDishTask,
  calcRecipeCountInDishTask,
} from '../../../utils/calculator';

import { put } from 'helpers/apiHelpers';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';

export const roundAndParse = (val, digit = 4) =>
  parseFloat(parseFloat(val ?? 0).toFixed(digit));

const FlowNumberInput = ({
  value,
  name,
  onChange,
  disabled,
  suffix,
  style,
}) => {
  const [controlled, setControlled] = useState(value);

  useEffect(() => {
    if (parseFloat(value) !== parseFloat(controlled)) {
      onChange(parseFloat(controlled), name);
    }
  }, [parseFloat(controlled)]);

  useEffect(() => {
    if (parseFloat(value) !== parseFloat(controlled)) {
      setControlled(parseFloat(value));
    }
  }, [parseFloat(value)]);

  return (
    <TextField
      style={{ width: '100%', ...style }}
      id="countDone"
      InputProps={{
        className: 'hidden-arrows',
        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
      }}
      value={roundAndParse(controlled)}
      placeholder="0,00"
      onChange={e => {
        let val = e.target.value;
        if (val < 0) {
          setControlled(0);
          return;
        }

        if (e.nativeEvent.data !== '.') {
          if ((val + '').includes('.') || (val + '').includes(',')) {
            const parts = (val + '').includes('.')
              ? (val + '').split('.')
              : (val + '').split(',');

            setControlled(
              parseFloat(`${parts[0]}.${parts[1].substring(0, 4)}`)
            );
          } else {
            setControlled(val);
          }
        }
      }}
      size="small"
      type="number"
      variant="standard"
      disabled={disabled}
    />
  );
};

const styleFactory = recipeTaskInfo => ({
  column: {
    maxWidth: !recipeTaskInfo.recipe.type ? 160 : 100,
  },
  input: {
    maxWidth: !recipeTaskInfo.recipe.type ? '48%' : '100%',
  },
});

export const RecipeTypeController = ({
  recipeTaskInfo,
  piece,
  divider,
  weight,
}) => {
  return (
    <>
      {(!recipeTaskInfo.recipe.type ||
        recipeTaskInfo.recipe.type === 'PIECE') && <>{piece}</>}
      {!recipeTaskInfo.recipe.type && <>{divider}</>}
      {(!recipeTaskInfo.recipe.type ||
        recipeTaskInfo.recipe.type === 'WEIGHT') && <>{weight}</>}
    </>
  );
};
const PerDishAmountInput = ({
  recipeTaskInfo,
  parentAmounts,
  setParentAmounts,
  footer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!recipeTaskInfo || (recipeTaskInfo.dishTasks ?? []).length === 0) {
    return (
      <Snackbar>
        <SnackbarContent variant="outlined">{t('$.error')}</SnackbarContent>
      </Snackbar>
    );
  }

  const customStyles = styleFactory(recipeTaskInfo);

  return (
    <Table>
      <TableHead>
        <TableRow
          style={{
            height: 'initial',
            background: theme.palette.action.active,
          }}
        >
          <TableCell
            padding="dense"
            variant="head"
            style={{
              textAlign: 'left',
              color: '#fff',
            }}
          >
            {t('basket.details.BasketItemDish.title')}
          </TableCell>
          <TableCell
            padding="dense"
            colSpan={3}
            variant="head"
            style={{
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {t('columns.qty')}
          </TableCell>
        </TableRow>
        <TableRow
          style={{
            height: 'initial',
            background: theme.palette.action.active,
          }}
        >
          <TableCell padding="dense" variant="head"></TableCell>
          <TableCell
            padding="dense"
            variant="head"
            style={{
              maxWidth: 100,
              color: '#fff',
              textAlign: 'center',
            }}
          >
            {t('$*cateringsFlow.kitchen.recipiesList.column.countToDo')}
          </TableCell>
          <TableCell
            padding="dense"
            variant="head"
            style={{
              maxWidth: 100,
              color: '#fff',
              textAlign: 'center',
            }}
          >
            {t(
              '$*cateringsFlow.kitchen.recipiesList.column.countDone',
              '$$Ilość zrobiona'
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {recipeTaskInfo.dishTasks.map(el => {
          return (
            <TableRow>
              <TableCell>
                {el.dish.workingName ?? el.dish.nameForClient}
              </TableCell>
              <TableCell style={{ ...customStyles.column }}>
                <RecipeTypeController
                  recipeTaskInfo={recipeTaskInfo}
                  piece={
                    <>
                      {calcRecipeCountInDishTask({
                        dishTaskMetadata: el.taskMetadata,
                        recipeId: recipeTaskInfo?.recipe?.id,
                        regularAmount: el?.amountToMake,
                        updatedAmount: el?.amountToMakeAfterRefreshedAmounts,
                      })}{' '}
                      szt
                    </>
                  }
                  divider={'/'}
                  weight={
                    <>
                      {roundAndParse(
                        calcRecipeWeightInDishTask({
                          dishTaskMetadata: el.taskMetadata,
                          recipeId: recipeTaskInfo?.recipe?.id,
                          regularAmount: el?.amountToMake,
                          updatedAmount: el?.amountToMakeAfterRefreshedAmounts,
                        }) / 1000
                      )}{' '}
                      {t('$*common.kg', 'kg')}
                    </>
                  }
                />
              </TableCell>
              <TableCell style={{ ...customStyles.column }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <RecipeTypeController
                    recipeTaskInfo={recipeTaskInfo}
                    piece={
                      <FlowNumberInput
                        style={{ ...customStyles.input }}
                        suffix={t('orders.addons.quantity', 'szt')}
                        value={parentAmounts[el.id].amountOfDone ?? 0}
                        onChange={v => {
                          setParentAmounts(pv => ({
                            ...pv,
                            [el.id]: {
                              ...pv[el.id],
                              amountOfDone: v,
                            },
                          }));
                        }}
                        disabled={
                          parentAmounts[el.id].amountToMake === 0 ||
                          parentAmounts[el.id].amountToMake ===
                            Number(0).toFixed(2) ||
                          false
                        }
                      />
                    }
                    divider={undefined}
                    weight={
                      <FlowNumberInput
                        style={{ ...customStyles.input }}
                        suffix={t('$*common.kg', 'kg')}
                        value={
                          (parentAmounts[el.id].amountOfDone *
                            recipeTaskInfo?.recipe?.macrosAfterProcessing
                              ?.weight) /
                          1000
                        }
                        onChange={v => {
                          setParentAmounts(pv => ({
                            ...pv,
                            [el.id]: {
                              ...pv[el.id],
                              amountOfDone:
                                (v * 1000) /
                                recipeTaskInfo?.recipe?.macrosAfterProcessing
                                  ?.weight,
                            },
                          }));
                        }}
                        disabled={
                          parentAmounts[el.id].amountToMake === 0 ||
                          parentAmounts[el.id].amountToMake ===
                            Number(0).toFixed(2) ||
                          false
                        }
                      />
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>{footer}</TableFooter>
    </Table>
  );
};

const mapDishToAmounts = recipeTaskInfo =>
  (recipeTaskInfo?.dishTasks ?? [])
    .map(el => {
      return {
        id: el.id,
        amountOfDone:
          recipeTaskInfo.amountDetails.find(ad => ad.requiredByTaskId === el.id)
            ?.amountOfDone ?? 0.0,
        amountToMake: el.amountToMake,
      };
    })
    .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

const AmountOfDoneModal = ({
  classes,
  recipeInfo = {},
  closeModal,
  handleSubmit,
  allowChangeAmountToMake = false,
  amountOfDoneModalNewStatus,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(recipeInfo?.comment ?? '');
  const [amountOfDone, setAmountOfDone] = useState(
    recipeInfo?.amountOfDone ?? 0
  );
  const [amountToMake, setAmountToMake] = useState(
    recipeInfo?.amountToMake ?? 0
  );

  const amountToMakeAfterUpdate =
    recipeInfo?.amountToMakeAfterRefreshedAmounts ?? 0;

  const [parentAmounts, setParentAmounts] = useState(
    mapDishToAmounts(recipeInfo)
  );
  const [sheduleStatusChange, setSheduleStatusChange] = useState(
    recipeInfo?.plannedStatusChangeAt ? true : false
  );
  const [sheduleStatusChangeDate, setSheduleStatusChangeDate] = useState(
    recipeInfo?.plannedStatusChangeAt
      ? new Date(recipeInfo?.plannedStatusChangeAt)
      : new Date()
  );

  const parentAmountOfDoneSum = () =>
    Object.keys(parentAmounts)
      .map(el => parentAmounts[el].amountOfDone)
      .reduce((acc, curr) => acc + parseFloat(curr), 0);

  useEffect(() => {
    setAmountOfDone(parseFloat(parentAmountOfDoneSum()));
  }, [parentAmounts]);
  const customStyles = styleFactory(recipeInfo);

  const footer = (
    <TableRow
      style={{
        height: 'initial',
        background: '#fff',
      }}
    >
      <TableCell padding="dense" variant="head"></TableCell>
      <TableCell
        padding="dense"
        variant="head"
        style={{ ...customStyles.column, color: '#fff' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <RecipeTypeController
            recipeTaskInfo={recipeInfo}
            piece={
              <FlowNumberInput
                style={{ ...customStyles.input }}
                suffix={t('orders.addons.quantity', 'szt')}
                value={
                  amountToMakeAfterUpdate
                    ? amountToMakeAfterUpdate
                    : amountToMake
                }
                onChange={v => false}
                disabled={true}
              />
            }
            divider={undefined}
            weight={
              <FlowNumberInput
                style={{ ...customStyles.input }}
                suffix={t('$*common.kg', 'kg')}
                value={
                  ((amountToMakeAfterUpdate
                    ? amountToMakeAfterUpdate
                    : amountToMake) *
                    recipeInfo?.recipe?.macrosAfterProcessing?.weight) /
                  1000
                }
                onChange={v => {
                  setAmountToMake(v);
                }}
                disabled={!allowChangeAmountToMake}
              />
            }
          />
        </div>
      </TableCell>
      <TableCell
        padding="dense"
        variant="head"
        style={{ ...customStyles.column, color: '#fff' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <RecipeTypeController
            recipeTaskInfo={recipeInfo}
            piece={
              <FlowNumberInput
                style={{ ...customStyles.input }}
                suffix={t('orders.addons.quantity', 'szt')}
                value={amountOfDone}
                onChange={() => false}
                disabled={true}
              />
            }
            divider={undefined}
            weight={
              <FlowNumberInput
                style={{ ...customStyles.input }}
                suffix={t('$*common.kg', 'kg')}
                value={
                  (amountOfDone *
                    recipeInfo?.recipe?.macrosAfterProcessing?.weight) /
                  1000
                }
                onChange={() => false}
                disabled={true}
              />
            }
          />
        </div>
      </TableCell>
    </TableRow>
  );
  return (
    <>
      <DialogTitle style={{ textAlign: 'center' }}>
        {recipeInfo?.name}
      </DialogTitle>
      <DialogContent style={{ minHeight: '400px' }}>
        {amountOfDoneModalNewStatus ===
          CATERINGS_FLOW_STATUSES.DONE_WAITING && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              checked={sheduleStatusChange}
              onClick={() => setSheduleStatusChange(!sheduleStatusChange)}
            />
            <InputLabel htmlFor="sheduleStatusChange">
              {t(
                '$*cateringsFlow.kitchen.amountOfDoneModal.sheduleStatusChange',
                "$$Zaplanuj zmianę statusu na 'Gotowe':"
              )}
            </InputLabel>
            <div style={{ marginLeft: '15px' }}>
              <Datetime
                timeFormat={true}
                closeOnSelect={true}
                value={sheduleStatusChangeDate}
                onChange={ev =>
                  setSheduleStatusChangeDate(ev.format('DD.MM.YYYY HH:mm'))
                }
                isValidDate={day =>
                  day.isAfter(Datetime.moment().subtract(1, 'day'))
                }
                inputProps={{
                  placeholder: t('sms.setDate'),
                  readOnly: true,
                }}
              />
            </div>
            <InfoIconTooltip
              title={t(
                '$*cateringsFlow.kitchen.amountOfDoneModal.sheduleStatusChange.tooltip',
                '$$Planując zmianę statusu, zmiana nastąpi automatycznie w ustalonym terminie'
              )}
            />
          </div>
        )}
        <div style={{ paddingBottom: '10px' }}>
          <PerDishAmountInput
            recipeTaskInfo={recipeInfo}
            parentAmounts={parentAmounts}
            setParentAmounts={setParentAmounts}
            footer={footer}
          />
        </div>
        <div>
          <InputLabel htmlFor="note">
            {t('$*cateringsFlow.kitchen.amountOfDoneModal.note', '$$Notatka')}
          </InputLabel>
          <TextField
            id="note"
            value={comment}
            maxRows={5}
            variant="standard"
            onChange={e => setComment(e.target.value)}
            multiline
            fullWidth
          />
        </div>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '10px',
        }}
      >
        <div style={{ padding: '0 10px' }}>
          <Button onClick={closeModal}>{t('form.cancel', '$$Anuluj')}</Button>
        </div>
        <div style={{ padding: '0 10px' }}>
          <Button
            color="success"
            onClick={() => {
              const hasSheduledStatusChangeChanged =
                (recipeInfo?.plannedStatusChangeAt && !sheduleStatusChange) ||
                sheduleStatusChange;

              if (
                amountOfDoneModalNewStatus ===
                  CATERINGS_FLOW_STATUSES.DONE_WAITING &&
                hasSheduledStatusChangeChanged
              ) {
                try {
                  put(`/tasks/${recipeInfo?.id}/schedule-status-change`, {
                    plannedStatus: sheduleStatusChange
                      ? CATERINGS_FLOW_STATUSES.DONE
                      : null,
                    plannedStatusChangeAt: sheduleStatusChangeDate,
                  });
                } catch (e) {
                  console.log(e);
                }
              }
              handleSubmit({
                amountOfDone: amountOfDone,
                amountToMake: amountToMake,
                amountOfDoneDetails: Object.keys(parentAmounts).map(el => ({
                  requiredByTask: `/pack-dish-tasks/${el}`,
                  amountOfDone: parentAmounts[el].amountOfDone,
                })),
                comment,
              });
            }}
          >
            {t('form.save', '$$Zapisz')}
          </Button>
        </div>
      </div>
    </>
  );
};

const AmountOfDoneModalController = ({ isOpened, ...props }) => {
  return (
    <Dialog
      maxWidth={'md'}
      open={isOpened}
      onClose={props.closeModal}
      fullWidth
    >
      {isOpened && props.recipeInfo && <AmountOfDoneModal {...props} />}
    </Dialog>
  );
};

export default AmountOfDoneModalController;
