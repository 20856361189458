import useGetTranslatableObject from './useGetTranslatableObject';

const useGetFormDefaultState = () => {
  const getTranslatableObject = useGetTranslatableObject();

  return {
    recipes: [],
    nameForClient: getTranslatableObject(),
    workNameState: '',
    clientNameState: '',
    recipe: '',
    recipeState: '',
    type: null,
    difficulty: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
    ],
    difficultyState: 1,
    tags: [],
    selectedTags: [],
    selectedAllergens: [],
    kitchenImageState: null,
    defaultWorker: null,
    clientImageState: null,
    ingredients: [],
    userIngredients: [],
    purposeState: '',
    historyHidden: true,
    preparationTime: 0,
  };
};

export default useGetFormDefaultState;
