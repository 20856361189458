import { useSelector } from 'react-redux';
import { get, put } from 'helpers/apiHelpers';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';

import makeStyles from '@material-ui/styles/makeStyles';
import TOAST_DURATIONS from 'helpers/toastDurations';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import SelectInput from 'components/FormSelect/SelectInput';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  card: { marginTop: '30px' },
  gridItem: {
    marginTop: '15px',
  },
});

const SettingsKwgGroup = ({ openToast }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector(state => state.Auth.user);

  const [allergens, setAllergens] = useState([]);
  const [allergensAtProductionLine, setAllergensAtProductionLine] = useState([]);

  const [state, setState] = useState({
    presenceOfPests: '',
    presenceOfForeignBodies: '',
    gmo: '',
    irradiation: '',
    organolepticCharacteristics: '',
    allergens: [],
    allergensAtProductionLine: [],
    collectivePackaging: '',
    transportAndDistribution: '',
    shelfLife: '',
    storageConditions: '',
    manufacturerData: '',
    nameOfApproves: '',
  });

  const index = user.company.lastIndexOf('/') + 1;
  const companyId = user.company.slice(index, user.company.length);

  useEffect(() => {
    get('/allergens', { partial: false })
      .then(response => {
        setAllergens(response['hydra:member']);
        setAllergensAtProductionLine(response['hydra:member']);
      })
      .catch(err => {
        console.log(err);
      });

    get(`/company/${companyId}/modules`).then(({ configuration }) => {
      const {
        characteristic: {
          presenceOfPests,
          presenceOfForeignBodies,
          gmo,
          irradiation,
          organolepticCharacteristics,
          collectivePackaging,
        },
        recommendations: {
          transportAndDistribution,
          shelfLife,
          storageConditions,
        },
        responsibleEntities: { manufacturerData, nameOfApproves },
      } = configuration.KWG;

      setState({
        presenceOfPests,
        presenceOfForeignBodies,
        gmo,
        irradiation,
        organolepticCharacteristics,
        allergensAtProductionLine: configuration.KWG.characteristic.allergensAtProductionLine,
        allergens: configuration.KWG.characteristic.allergens,
        collectivePackaging,
        transportAndDistribution,
        shelfLife,
        storageConditions,
        manufacturerData,
        nameOfApproves,
      });
    });
  }, []);

  const handleChange = e => {
    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    const {
      presenceOfPests,
      presenceOfForeignBodies,
      gmo,
      irradiation,
      organolepticCharacteristics,
      collectivePackaging,
      transportAndDistribution,
      shelfLife,
      storageConditions,
      manufacturerData,
      nameOfApproves,
    } = state;

    const data = {
      configuration: {
        KWG: {
          characteristic: {
            presenceOfPests,
            presenceOfForeignBodies,
            gmo,
            irradiation,
            organolepticCharacteristics,
            allergensAtProductionLine: state.allergensAtProductionLine,
            allergens: state.allergens,
            collectivePackaging,
          },
          recommendations: {
            transportAndDistribution,
            shelfLife,
            storageConditions,
          },
          responsibleEntities: {
            manufacturerData,
            nameOfApproves,
          },
        },
      },
    };

    return put(`/company/${companyId}/modules`, data)
      .then(() => {
        openToast({
          messages: [
            t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
          ],
          type: 'success',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      })
      .catch(() => {
        openToast({
          messages: [t('menuPlanner.errors.somethingGoWrong')],
          type: 'error',
          autoHideDuration: TOAST_DURATIONS.SM,
        });
      });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('form.settingsKwg.characteristic')}>
            <CardBody className={classes.card}>
              <GridContainer>
                <GridItem xs={6}>
                  <FormTextInput
                    label={t('form.settingsKwg.pests')}
                    name="presenceOfPests"
                    value={state.presenceOfPests}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                  <FormTextInput
                    label={t('form.settingsKwg.foreignBodies')}
                    name="presenceOfForeignBodies"
                    value={state.presenceOfForeignBodies}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                  <FormTextInput
                    label={t('form.settingsKwg.gmo')}
                    name="gmo"
                    value={state.gmo}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                  <FormTextInput
                    label={t('form.settingsKwg.irridation')}
                    name="irradiation"
                    value={state.irradiation}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <FormTextInput
                    label={t('form.settingsKwg.organolepticCharacteristics')}
                    name="organolepticCharacteristics"
                    value={state.organolepticCharacteristics}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                  <SelectInput
                    multiple={true}
                    classes={classes}
                    label={t('form.settingsKwg.allergensAtProductionLine')}
                    mapBy="value"
                    trackBy="@id"
                    value={state.allergensAtProductionLine}
                    options={allergensAtProductionLine}
                    name="allergensAtProductionLine"
                    handleChange={handleChange}
                  />
                  <SelectInput
                    multiple={true}
                    classes={classes}
                    label={t('form.settingsKwg.allergensAtPlant')}
                    mapBy="value"
                    trackBy="@id"
                    value={state.allergens}
                    options={allergens}
                    name="allergens"
                    handleChange={handleChange}
                  />
                  <FormTextInput
                    label={t('form.settingsKwg.bulkPacking')}
                    name="collectivePackaging"
                    value={state.collectivePackaging}
                    classes={classes}
                    maxLength={255}
                    handleChange={handleChange}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('form.settingsKwg.transportAndStorage')}>
            <CardBody className={classes.card}>
              <GridItem xs={6}>
                <FormTextInput
                  label={t('form.settingsKwg.transport')}
                  name="transportAndDistribution"
                  value={state.transportAndDistribution}
                  classes={classes}
                  maxLength={255}
                  handleChange={handleChange}
                />
                <FormTextInput
                  label={t('form.settingsKwg.period')}
                  name="shelfLife"
                  value={state.shelfLife}
                  classes={classes}
                  maxLength={255}
                  handleChange={handleChange}
                />
                <FormTextInput
                  label={t('form.settingsKwg.storageConditions')}
                  name="storageConditions"
                  value={state.storageConditions}
                  classes={classes}
                  maxLength={255}
                  handleChange={handleChange}
                />
              </GridItem>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('form.settingsKwg.responsibleEntities')}>
            <CardBody className={classes.card}>
              <GridItem xs={6}>
                <FormTextInput
                  label={t('form.settingsKwg.manufactureData')}
                  name="manufacturerData"
                  value={state.manufacturerData}
                  classes={classes}
                  maxLength={255}
                  handleChange={handleChange}
                />
                <FormTextInput
                  label={t('form.settingsKwg.personalDataApprovingPerson')}
                  name="nameOfApproves"
                  value={state.nameOfApproves}
                  classes={classes}
                  maxLength={255}
                  handleChange={handleChange}
                />
              </GridItem>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>
      <FormControlStickyButton
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/meals"
        handleSubmit={handleSubmit}
        customOffsetSmall="10px"
        customOffsetLarge="10px"
        isFixedToBottom={true}
      />
    </>
  );
};

export default withToast(SettingsKwgGroup);
