import { FormatListBulleted, ListAlt } from '@material-ui/icons';

import {
  TranslatableObjectKey,
  useGetCompanyDefaultLanguage,
  useTranslatableSelectedLanguage,
} from 'hooks/redux/Translatable/useTranslatableLanguages';
import LanguagePicker from 'components/Translatable/LanguagePicker';

const mapTranslationToValue = (
  translation,
  prefLang = 'pl',
  alternative = 'pl'
) => {
  if (typeof translation === 'string') {
    return translation;
  }

  const languageTranslations = translation[TranslatableObjectKey];

  if (Object.keys(languageTranslations).length === 0) {
    return null;
  }

  if (hasTranslation(languageTranslations, prefLang)) {
    return languageTranslations[prefLang];
  }
  if (hasTranslation(languageTranslations, alternative)) {
    return languageTranslations[alternative];
  }

  return languageTranslations[Object.keys(languageTranslations)[0]];
};

export const mapArrayOfTranslationsToPureArray = (
  arr,
  prefLang = 'pl',
  alternative = 'pl'
) => {
  return arr.map(el => mapTranslationToValue(el, prefLang, alternative));
};

const hasTranslation = (languageTranslations, language) =>
  languageTranslations.hasOwnProperty(language) &&
  languageTranslations[language] &&
  (languageTranslations[language] ?? '').trim() !== '';

const TranslatableReader = ({ value, ingredient }) => {
  const language = useTranslatableSelectedLanguage();
  const companyDefaultLanguage = useGetCompanyDefaultLanguage();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'flex-end',
      }}
    >
      <div style={{ width: 35, flex: '0 0 auto' }}>
        <LanguagePicker />
      </div>
      <div style={{ display: 'flex', marginLeft: '15px' }}>
        <span style={{ color: '#888888', marginRight: '10px' }}>
          {ingredient?.ingredient && <FormatListBulleted fontSize="small" />}
          {ingredient?.recipe && <ListAlt fontSize="small" />}
        </span>
        <span>
          {mapTranslationToValue(value, language, companyDefaultLanguage)}
        </span>
      </div>
    </div>
  );
};

export default TranslatableReader;
