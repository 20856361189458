import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, FormLabel } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import ReactSelectComponent from 'react-select';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import * as WebFont from 'webfontloader';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import SelectInput from 'components/FormSelect/SelectInput';
import FontPreview from 'components/FontPreview/FontPreview';

import ReactSelect from 'components/ReactSelect/ReactSelect';
import {
  combineStyles,
  loadFontToDocumentHead,
  slugify,
} from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import googleFonts from './googleFonts';
import allFontsLists from './fontsList';
import produce from 'immer';

const FontSelector = ({
  defaultFont = { label: 'Nunito Sans', value: 'Nunito Sans' },
  defaultFontLibrary = 'google',
  defaultFontLibraryParams = {},
  defaultFontsList = [...googleFonts],
  fontLibrariesList = [
    { label: 'Google Fonts', value: 'google' },
    { label: 'Adobe Fonts', value: 'typekit' },
    { label: 'Custom', value: 'custom' },
  ],
  classes,
  onUpdate = () => {},
}) => {
  const { t } = useTranslation();
  const [font, setFont] = useState(defaultFont);
  const [fontLibrary, setFontLibrary] = useState(defaultFontLibrary);
  const [fontLibraryParams, setFontLibraryParams] = useState(
    defaultFontLibraryParams
  );
  const [fontsList, setFontsList] = useState(defaultFontsList);

  useEffect(() => {
    loadFont({
      fontLibrary: fontLibrary,
      families: [font.value],
    });
  }, []);

  const handleFontChange = option => {
    setFont(option);
    loadFont({
      fontLibrary: fontLibrary,
      families: [option.value],
    });
    onUpdate({
      font: option.value,
      library: fontLibrary,
      libraryParams: fontLibraryParams,
    });
  };

  const handleFontLibraryChange = event => {
    const { value } = event.target;
    const libraryFonts = allFontsLists[value];

    setFont('');
    setFontLibrary(value);
    setFontLibraryParams({});
    setFontsList(libraryFonts);
    onUpdate({
      font: null,
      library: value,
      libraryParams: {},
    });
  };

  const handleFontLibraryParamsChange = ({ target }) => {
    const { name, value } = target;
    const newLibraryParams = produce(fontLibraryParams, draft => {
      draft[name] = value;
    });

    setFontLibraryParams(newLibraryParams);
    onUpdate({
      font: font.value,
      library: fontLibrary,
      libraryParams: newLibraryParams,
    });
  };

  const loadFont = ({ fontLibrary = 'google', families = ['Nunito Sans'] }) => {
    let webFontObject = {
      [fontLibrary]: {
        families: families,
      },
    };

    if (fontLibrary === 'typekit') {
      webFontObject = {
        typekit: fontLibraryParams,
      };
    }

    if (fontLibrary === 'custom') {
      const slug = slugify(families[0]);
      const url = `${process.env.REACT_APP_API_ENDPOINT}static/fonts/${slug}/${slug}.css`;
      loadFontToDocumentHead(url);

      webFontObject = {
        families: families,
      };
    }

    WebFont.load({
      ...webFontObject,
    });
  };

  const renderFontSelect = () => {
    if (fontLibrary === 'typekit' && !fontLibraryParams?.id) {
      return null;
    }

    return (
      <Fragment>
        <FormLabel className={classes.labelHorizontal}>
          <div style={{ display: 'flex' }}>
            <span>{t('$*form.field.clientFont.label', '$$Wybierz font')}*</span>
            {fontLibrary === 'typekit' && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        '$*form.field.clientFont.label.tooltip',
                        '$$Wybierz font dodany do projektu na "fonts.adobe.com"'
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{
                      color: 'grey',
                      display: 'block',
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </FormLabel>
        <ReactSelect
          defaultValue={[]}
          isMulti={false}
          selectedValues={font}
          options={fontsList}
          handleChange={handleFontChange}
          placeholder=" "
          noOptionsMessage={t(
            '$*noSearchResults',
            '$$Brak wyników wyszukiwania'
          )}
          Component={ReactSelectComponent}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <SelectInput
        style={{ padding: 0 }}
        noGrid
        classes={classes}
        mapBy="label"
        trackBy="value"
        name={'clientFontLibrary'}
        value={fontLibrary}
        options={fontLibrariesList}
        handleChange={handleFontLibraryChange}
      />

      {fontLibrary === 'typekit' && (
        <FormTextInput
          label={
            <div style={{ display: 'flex' }}>
              <span>Typekit ID*</span>
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        '$*form.field.clientFontLibraryParams.id.label.tooltip',
                        '$$Podaj "Project ID" z sekcji "Web Projects"'
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{
                      color: 'grey',
                      display: 'block',
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          }
          classes={classes}
          name="id"
          value={fontLibraryParams.id}
          handleChange={handleFontLibraryParamsChange}
        />
      )}

      {renderFontSelect()}

      {fontLibrary === 'typekit' &&
        t(
          '$*fontPreview.typekit',
          '$$Podgląd dostępny tylko dla fontów z wybranego projektu adobe fonts'
        )}
      <FontPreview fontLibrary={fontLibrary} fontFamily={font.value}>
        <b>{t('brandCfg.exampleText')}:</b> lorem ipsum <i>dolor</i>
      </FontPreview>
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles));

export default enhance(FontSelector);
